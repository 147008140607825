import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import {
  PopoverBody,
  UncontrolledTooltip,
  UncontrolledPopover,
} from 'reactstrap';
import inspector from 'schema-inspector';
import { asideMenuCssClasses } from '@coreui/react/lib/Shared';
import toggleClasses from '@coreui/react/lib/Shared/toggle-classes';
import { checkCircle, doNotEnter } from '../components/Fragments';
import Spinner from '../components/Spinner';

/**
 * Filters all localStorage entries via string match or regular expression.
 *
 * @param {string|Object} query - The search query in string or RegExp format
 * @param {string} [search="value"] - Specify `key` to perform a match on keys
 * @returns {(boolean|Object|Array)}
 */
export function findLocalStorageItems(query, search = 'value') {
  const results = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const match = (search === 'key') ? key : localStorage.getItem(key);
    if (match.match(query) || (!query && typeof match === 'string')) {
      const value = localStorage.getItem(key);
      results.push({ key, val: value });
    }
  }

  return results.length > 1 ?
    results :
    (results.length > 0 ?
      results[0] :
      false);
}

export function clearLocalStorageAuth() {
  _.forIn(localStorage, (value, objKey) => {
    if (
      _.startsWith(objKey, 'CognitoIdentityServiceProvider') ||
      _.startsWith(objKey, 'activeAppClientId') ||
      _.startsWith(objKey, 'aws-amplify-cachefederatedInfo')
    ) {
        localStorage.removeItem(objKey);
    }
  });
}

/**
 * Searches for a signifying key in local app storage.
 *
 * @returns {boolean}
 */
export function isAuthenticated() {
  const pattern = '(CognitoIdentityServiceProvider\\..*\\.idToken|aws-amplify-cachefederatedInfo)';

  return findLocalStorageItems(new RegExp(pattern), 'key') !== false;
}

/**
 * Checks for whether a given email address is a federated account.
 *
 * @param {string} email - Email address
 * @param {Object} federatedDomains - The domain map from AppSync
 * @returns {boolean}
 */
export function isUserFederated(email, federatedDomains) {
  const domain = email.split('@')[1];
  if (
    domain === 'chartspan.com' &&
    email.includes('+') &&
    !email.includes('okta') // maintains SSO for Okta test accounts
  ) {
    return false;
  }

  return Object.keys(federatedDomains).includes(domain);
}

/**
 * Opens and closes the main slideout panel for the app.
 *
 * @param {string} action - Whether to `open` or `close`
 * @returns void
 */
export function switchSlideoutPanel(action) {
  if (action === 'close') {
    toggleClasses('aside-menu-lg-show', asideMenuCssClasses, false);
  } else if (action === 'open') {
    toggleClasses('aside-menu-lg-show', asideMenuCssClasses, true);
  }
}
/**
 * Opens and closes fixed slideout panels.
 *
 * @param {string} action - Whether to `open` or `close`
 * @returns void
 */
export function switchFixedSlideoutPanel(action) {
  if (action === 'close') {
    toggleClasses('view-aside-menu-lg-show', asideMenuCssClasses, false);

    if (document.body.classList.contains('aside-menu-fixed')) {
      // checking for existence of the `aside-menu-fixed` class prevents removing these classes every time
      // the route changes (which calls these methods).
      setTimeout(() => {
        document.body.classList.remove('aside-menu-fixed', 'view-aside-menu');
      }, 500);
    }
  } else if (action === 'open') {
    document.body.classList.add('aside-menu-fixed', 'view-aside-menu');
    toggleClasses('view-aside-menu-lg-show', asideMenuCssClasses, true);
  }
}

/**
 * Translates address fields from flat form input to nested structure for the GQL service.
 *
 * @param {Object} flatValues - The form values
 * @param {Object|null} serviceObject - The object being built for service consumption
 * @returns object
 */
export function translateFormAddressForService(flatValues, serviceObject) {
  const address = {};

  ['streetAddress', 'city', 'state', 'zip'].forEach((prop) => {
    if (flatValues[prop] !== '') {
      address[prop] = flatValues[prop];
    }
  });

  if (!_.isEmpty(address)) {
    serviceObject.address = address;
  }

  return serviceObject;
}

/**
 * Translates blank strings to `null` values for consumption by service.
 *
 * @param {Object} values - The form values
 * @returns object
 */
export function translateFormEmptiesForService(values) {
  _.forEach(values, (val, i) => {
    values[i] = val === '' ? null : val;
  });

  return values;
}

/**
 * Translates phone fields from flat form input to nested structure for the GQL service.
 *
 * @param {Object} flatValues - The form values
 * @param {Object} serviceObject - The object being built for service consumption
 * @returns object
 */
export function translateFormPhoneForService(flatValues, serviceObject) {
  const phoneNumber = {};

  ['fax', 'office'].forEach((prop) => {
    phoneNumber[prop] = flatValues[prop] !== '' ? flatValues[prop] : null;
  });

  if (!_.isEmpty(phoneNumber)) {
    serviceObject.phoneNumber = phoneNumber;
  }

  return serviceObject;
}

/**
 * Translates address fields from nested GQL service structure to flat form input.
 *
 * @param {Object} serviceObject - Object from GQL service
 * @param {Object} flatValues - The object being built for form consumption
 * @returns object
 */
export function translateServiceAddressForForm(serviceObject, flatValues) {
  const addressFields = ['streetAddress', 'city', 'state', 'zip'];
  addressFields.forEach(prop => flatValues[prop] = serviceObject.address ? serviceObject.address[prop] || '' : '');

  return flatValues;
}

/**
 * Translates address fields from nested GQL service structure to flat form input.
 *
 * @param {Object} serviceObject - Object from GQL service
 * @param {Object} flatValues - The object being built for form consumption
 * @returns object
 */
export function translateServicePhoneForForm(serviceObject, flatValues) {
  const phoneFields = ['fax', 'office'];
  phoneFields.forEach(prop => flatValues[prop] = serviceObject.phoneNumber ? serviceObject.phoneNumber[prop] || '' : '');

  return flatValues;
}

/**
 * A helper for validating reducer data against `schema-inspector` schema definitions.
 *
 * @param {Object} schema - A `schema-inspector` schema definition
 * @param {Object|null} state - The state to validate
 * @returns void
 * @throws {Error}
 */
export function validateReducerData(schema, state) {
  const schemaCheck = inspector.validate(schema, state);

  if (!schemaCheck.valid) {
    throw Error(schemaCheck.format());
  }
}

/**
 * A function for use by `BootstrapTable` to sort the `hasVitals` column.
 */
export function vitalsSorter(a, b, order) {
  if (order === 'desc') {
    return a ? (a.props.className === 'text-danger' ? -1 : 1) : 0;
  }

  return a ? (a.props.className === 'text-danger' ? 1 : -1) : 0;
}

/**
 * A formatter function for rendering a PDF report download popover.
 */
export function reportDownloadFormatter(hra, patient, genericStore, clickHandler) {
  return (hra.s3KeyPatientReport || hra.s3KeyProviderReport) && (
    <div className="row-actions">
      <a href={`#${hra.id}`} id={`reportdl-${hra.id}`} onClick={e => e.preventDefault(e)}>
        <FontAwesomeIcon icon={['fad', 'cloud-download-alt']} size="lg" />
      </a>
      <UncontrolledPopover placement="bottom" trigger="legacy" target={`reportdl-${hra.id}`}>
        <PopoverBody className="reportdl-popover">
          {hra.s3KeyPatientReport && (
            <React.Fragment>
              <a href={`#${hra.id}`} onClick={e => clickHandler(e, hra.id, patient.orgID, patient.id, 'patient')}>
                <FontAwesomeIcon icon={['fas', 'file-pdf']} size="lg" />
                Patient
                {genericStore.pdfIsLoading === 'patient' && (<Spinner className="pdfSpinner" />)}
                {genericStore.pdfError === 'patient' && (<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="float-right" />)}
              </a>
              <hr />
            </React.Fragment>
          )}
          {hra.s3KeyProviderReport && (
            <a href={`#${hra.id}`} onClick={e => clickHandler(e, hra.id, patient.orgID, patient.id, 'provider')}>
              <FontAwesomeIcon icon={['fas', 'file-pdf']} size="lg" />
              Provider
              {genericStore.pdfIsLoading === 'provider' && (<Spinner className="pdfSpinner" />)}
              {genericStore.pdfError === 'provider' && (<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="float-right" />)}
            </a>
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
}

// eslint-disable-next-line react/prop-types
export function reportDeliveryStatusFormatter({ reportDeliveredPatient, reportDeliveredProvider }) {
  const statuses = [reportDeliveredPatient, reportDeliveredProvider];

  if (statuses.every(i => i === null)) {
    // assume this HRA is older than the report delivery feature
    return;
  }

  if (process.env.REACT_APP_ENV === 'staging') {
    // always show a checkmark in stage for demo purposes
    return checkCircle;
  }

  if (statuses.every(i => i === false)) {
    return doNotEnter;
  }

  if (statuses.some(i => i === false)) {
    let infoString = `${!reportDeliveredPatient ? 'patient' : ''}, ${!reportDeliveredProvider ? 'provider' : ''}`;
    infoString = _.trim(infoString, ',');

    return (
      <React.Fragment>
        <FontAwesomeIcon
          icon={['fad', 'exclamation-triangle']}
          size="lg"
          className="text-warning"
          style={{
            '--fa-primary-color': 'black',
            '--fa-secondary-opacity': '1.0',
          }}
          id="ehr-integration-warning"
        />
        <UncontrolledTooltip placement="bottom" target="ehr-integration-warning">Missing: {infoString}</UncontrolledTooltip>
      </React.Fragment>
    );
  }

  if (statuses.every(i => i === true)) {
    return checkCircle;
  }
}

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
