export const CLEAR_USER_EDIT_FORM_ERROR = 'CLEAR_USER_EDIT_FORM_ERROR';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USER_FOR_SLIDEOUT_FAILURE = 'GET_USER_FOR_SLIDEOUT_FAILURE';
export const GET_USER_FOR_SLIDEOUT_REQUEST = 'GET_USER_FOR_SLIDEOUT_REQUEST';
export const GET_USER_FOR_SLIDEOUT_SUCCESS = 'GET_USER_FOR_SLIDEOUT_SUCCESS';
export const RESET_USER_CREATE_FORM = 'RESET_USER_CREATE_FORM';
export const RESET_TEMPORARY_PASSWORD_FAILURE = 'RESET_TEMPORARY_PASSWORD_FAILURE';
export const RESET_TEMPORARY_PASSWORD_REQUEST = 'RESET_TEMPORARY_PASSWORD_REQUEST';
export const RESET_TEMPORARY_PASSWORD_SUCCESS = 'RESET_TEMPORARY_PASSWORD_SUCCESS';
export const UPDATE_USER_EDIT_FORM = 'UPDATE_USER_EDIT_FORM';
export const UPDATE_USER_EDIT_FORM_LOCATIONS = 'UPDATE_USER_EDIT_FORM_LOCATIONS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USERS_DATA = 'UPDATE_USERS_DATA';
export const UPDATE_USERS_SCOPEFILTER = 'UPDATE_USERS_SCOPEFILTER';
