export const CLEAR_PROVIDER_EDIT_FORM_ERROR = 'CLEAR_PROVIDER_EDIT_FORM_ERROR';
export const CREATE_PROVIDER_FAILURE = 'CREATE_PROVIDER_FAILURE';
export const CREATE_PROVIDER_REQUEST = 'CREATE_PROVIDER_REQUEST';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const FETCH_PROVIDER_LOCATIONS_FAILURE = 'FETCH_PROVIDER_LOCATIONS_FAILURE';
export const FETCH_PROVIDER_LOCATIONS_REQUEST = 'FETCH_PROVIDER_LOCATIONS_REQUEST';
export const FETCH_PROVIDER_LOCATIONS_SUCCESS = 'FETCH_PROVIDER_LOCATIONS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';
export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const UPDATE_PROVIDERS_DATA = 'UPDATE_PROVIDERS_DATA';
export const UPDATE_PROVIDER_FAILURE = 'UPDATE_PROVIDER_FAILURE';
export const UPDATE_PROVIDERS_SCOPEFILTER = 'UPDATE_PROVIDERS_SCOPEFILTER';
