import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CALC_RISKMATRIX_FAILURE,
  CALC_RISKMATRIX_REQUEST,
  CALC_RISKMATRIX_SUCCESS,
  UPDATE_REPORTING_SCOPEFILTER,
} from '../types/reportingTypes';

const schema = {
  type: 'object',
  properties: {
    data: { type: ['object', 'null', 'array'], optional: true },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: { type: ['object'], optional: true },
  },
};

const initialState = {
  data: null,
  error: null,
  loading: false,
  scopeFilter: {},
};

const reportingReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CALC_RISKMATRIX_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case CALC_RISKMATRIX_REQUEST: {
      return { ...state, loading: true };
    }
    case CALC_RISKMATRIX_SUCCESS: {
      return { ...state, loading: false, data: action.payload, error: null };
    }

    case SET_INITIAL_STATE:
      return initialState;

    case UPDATE_REPORTING_SCOPEFILTER: {
      return {
        ...state,
        scopeFilter: {
          organizations: action.organizations,
          locations: action.locations,
          providers: action.providers,
        },
      };
    }

    default:
      return state;
  }
};

export default reportingReducer;
