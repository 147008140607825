export const CREATE_CUSTOMSURVEY_FAILURE = 'CREATE_CUSTOMSURVEY_FAILURE';
export const CREATE_CUSTOMSURVEY_REQUEST = 'CREATE_CUSTOMSURVEY_REQUEST';
export const CREATE_CUSTOMSURVEY_SUCCESS = 'CREATE_CUSTOMSURVEY_SUCCESS';
export const CUSTOMSURVEYS_SCOPEFILTER_UPDATE = 'CUSTOMSURVEYS_SCOPEFILTER_UPDATE';
export const FETCH_CUSTOMSURVEYBYID_FAILURE = 'FETCH_CUSTOMSURVEYBYID_FAILURE';
export const FETCH_CUSTOMSURVEYBYID_REQUEST = 'FETCH_CUSTOMSURVEYBYID_REQUEST';
export const FETCH_CUSTOMSURVEYBYID_SUCCESS = 'FETCH_CUSTOMSURVEYBYID_SUCCESS';
export const FETCH_CUSTOMSURVEYS_FAILURE = 'FETCH_CUSTOMSURVEYS_FAILURE';
export const FETCH_CUSTOMSURVEYS_REQUEST = 'FETCH_CUSTOMSURVEYS_REQUEST';
export const FETCH_CUSTOMSURVEYS_SUCCESS = 'FETCH_CUSTOMSURVEYS_SUCCESS';
export const PROPOGATE_BUILDER_TO_STORE = 'PROPOGATE_BUILDER_TO_STORE';
export const REFETCH_CUSTOMSURVEY_FAILURE = 'REFETCH_CUSTOMSURVEY_FAILURE';
export const REFETCH_CUSTOMSURVEY_REQUEST = 'REFETCH_CUSTOMSURVEY_REQUEST';
export const REFETCH_CUSTOMSURVEY_SUCCESS = 'REFETCH_CUSTOMSURVEY_SUCCESS';
export const SAVE_FROM_BUILDER = 'SAVE_FROM_BUILDER';
export const UPDATE_CUSTOMSURVEY_FAILURE = 'UPDATE_CUSTOMSURVEY_FAILURE';
export const UPDATE_CUSTOMSURVEY_REQUEST = 'UPDATE_CUSTOMSURVEY_REQUEST';
export const UPDATE_CUSTOMSURVEY_SUCCESS = 'UPDATE_CUSTOMSURVEY_SUCCESS';
export const UPDATE_CUSTOMSURVEY_QUESTIONS_FAILURE = 'UPDATE_CUSTOMSURVEY_QUESTIONS_FAILURE';
export const UPDATE_CUSTOMSURVEY_QUESTIONS_REQUEST = 'UPDATE_CUSTOMSURVEY_QUESTIONS_REQUEST';
export const UPDATE_CUSTOMSURVEY_QUESTIONS_SUCCESS = 'UPDATE_CUSTOMSURVEY_QUESTIONS_SUCCESS';
