export const CALC_REVENUEMTD_FAILURE = 'CALC_REVENUEMTD_FAILURE';
export const CALC_REVENUEMTD_REQUEST = 'CALC_REVENUEMTD_REQUEST';
export const CALC_REVENUEMTD_SUCCESS = 'CALC_REVENUEMTD_SUCCESS';
export const FETCH_MTD_HRAS_FAILURE = 'FETCH_MTD_HRAS_FAILURE';
export const FETCH_MTD_HRAS_REQUEST = 'FETCH_MTD_HRAS_REQUEST';
export const FETCH_MTD_HRAS_SUCCESS = 'FETCH_MTD_HRAS_SUCCESS';
export const FETCH_PATIENT_COUNT_FAILURE = 'FETCH_PATIENT_COUNT_FAILURE';
export const FETCH_PATIENT_COUNT_REQUEST = 'FETCH_PATIENT_COUNT_REQUEST';
export const FETCH_PATIENT_COUNT_SUCCESS = 'FETCH_PATIENT_COUNT_SUCCESS';
export const UPDATE_DASHBOARD_SCOPEFILTER = 'UPDATE_DASHBOARD_SCOPEFILTER';
