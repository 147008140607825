/* eslint-disable camelcase */
import { Amplify, Auth, Logger } from 'aws-amplify';


Logger.LOG_LEVEL = process.env.REACT_APP_LOGLEVEL;

// Setup some Cognito configuration settings for export
const appClientId = localStorage.getItem('activeAppClientId') || process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID;
export const amplifyAuthConfig = {
  identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  region: process.env.REACT_APP_AWS_REGION,
  identityPoolRegion: process.env.REACT_APP_AWS_IDENTITY_POOL_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: appClientId,
  mandatorySignIn: false,
  oauthResponseType: 'code',
  oauthTokenScopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
};

export const configureAmplify = (withGql = false) => {
  const {
    REACT_APP_AWS_API_HOST: aws_appsync_graphqlEndpoint,
    REACT_APP_AWS_APPSYNC_REGION: aws_appsync_region,
    REACT_APP_AWS_APPSYNC_AUTHTYPE: aws_appsync_authenticationType,
  } = process.env;

  Amplify.configure({
    Auth: amplifyAuthConfig,
    aws_appsync_graphqlEndpoint,
    aws_appsync_region,
    aws_appsync_authenticationType,
    graphql_headers: withGql ? async () => ({
      Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
    }) : () => {},
  });
};
