import React from 'react';

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Patients = React.lazy(() => import('./views/Patients'));
const Users = React.lazy(() => import('./views/Users'));
const Billing = React.lazy(() => import('./views/Billing'));
const Organizations = React.lazy(() => import('./views/Organizations'));
const Locations = React.lazy(() => import('./views/Locations'));
const Profile = React.lazy(() => import('./views/Profile'));
const Providers = React.lazy(() => import('./views/Providers'));
const RiskInterventions = React.lazy(() => import('./views/Reporting/RiskInterventions'));
const CustomSurveys = React.lazy(() => import('./views/CustomSurveys'));
const CustomSurveyBuilder = React.lazy(() => import('./views/CustomSurveys/CustomSurveyBuilder'));
const AwvSchedule = React.lazy(() => import('./views/Awv/AwvSchedule'));
const AwvLocationSelect = React.lazy(() => import('./views/Awv/AwvLocationSelect'));
const AwvCreateAppointment = React.lazy(() => import('./views/Awv/AwvCreateAppointment'));
const HraCompleted = React.lazy(() => import('./views/Hra/HraCompleted'));
const HraLanding = React.lazy(() => import('./views/Hra/HraLanding'));
const HraSurvey = React.lazy(() => import('./views/Hra/HraSurveyContainer'));
const PinSelect = React.lazy(() => import('./views/Pin/PinSelect'));
const Privacy = React.lazy(() => import('./views/Pages/Privacy'));
const Terms = React.lazy(() => import('./views/Pages/Terms'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'RapidAWV' },
  { path: '/awv', exact: true, name: 'AWV', component: AwvSchedule },
  { path: '/awv/schedule', exact: true, name: 'Schedule', component: AwvSchedule },
  { path: '/awv/location-select', exact: true, name: 'Locations', component: AwvLocationSelect },
  { path: '/awv/create-appointment', exact: true, name: 'Create Appointment', component: AwvCreateAppointment },
  { path: '/billing', name: 'Billing', component: Billing },
  { path: '/custom-surveys/:id', name: 'Builder', component: CustomSurveyBuilder },
  { path: '/custom-surveys', name: 'Custom HRAs', component: CustomSurveys },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/hra/completed', exact: true, name: 'HRA Completed', component: HraCompleted },
  { path: '/hra/:id/:lang', name: 'Health Risk Assessment', component: HraSurvey },
  { path: '/hra/:id', name: 'Health Risk Assessment', component: HraLanding },
  { path: '/locations', name: 'Locations', component: Locations },
  { path: '/organizations', name: 'Organizations', component: Organizations },
  { path: '/patients', name: 'Patients', component: Patients },
  { path: '/pin-select', exact: true, name: 'PIN Select', component: PinSelect },
  { path: '/privacy', name: 'Privacy', component: Privacy },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/providers', name: 'Providers', component: Providers },
  { path: '/risk-interventions', name: 'RiskInterventions', component: RiskInterventions },
  { path: '/terms', name: 'Terms', component: Terms },
  { path: '/users', name: 'Users', component: Users },
];

export default routes;
