export const GET_APPOINTMENT_FOR_SURVEY_FAILURE = 'GET_APPOINTMENT_FOR_SURVEY_FAILURE';
export const GET_APPOINTMENT_FOR_SURVEY_REQUEST = 'GET_APPOINTMENT_FOR_SURVEY_REQUEST';
export const GET_APPOINTMENT_FOR_SURVEY_SUCCESS = 'GET_APPOINTMENT_FOR_SURVEY_SUCCESS';
export const GET_HRA_LANGUAGES_CANCEL = 'GET_HRA_LANGUAGES_CANCEL';
export const GET_HRA_LANGUAGES_FAILURE = 'GET_HRA_LANGUAGES_FAILURE';
export const GET_HRA_LANGUAGES_REQUEST = 'GET_HRA_LANGUAGES_REQUEST';
export const GET_HRA_LANGUAGES_SUCCESS = 'GET_HRA_LANGUAGES_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAILURE = 'GET_SURVEY_QUESTIONS_FAILURE';
export const GET_SURVEY_QUESTIONS_REQUEST = 'GET_SURVEY_QUESTIONS_REQUEST';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS';
export const PUT_HRA_FAILURE = 'PUT_HRA_FAILURE';
export const PUT_HRA_REQUEST = 'PUT_HRA_REQUEST';
export const PUT_HRA_SUCCESS = 'PUT_HRA_SUCCESS';
export const TOGGLE_EXIT_MODAL = 'TOGGLE_EXIT_MODAL';
