const abilities = {
  // API-Role-bound abilities
  LOC_ADMIN: [
    { subject: 'billing', actions: ['read', 'update'] },
    { subject: 'dashboard', actions: ['read'] },
    { subject: 'location', actions: ['read'] },
    { subject: 'user', actions: ['read'] },
    { subject: 'provider', actions: ['read'] },
    { subject: 'patient', actions: ['read', 'update'] },
    { subject: 'reporting', actions: ['read', 'update'] },
  ],
  ORG_ADMIN: [
    { subject: 'billing', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'dashboard', actions: ['read'] },
    { subject: 'location', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'organization', actions: ['read'] },
    { subject: 'user', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'provider', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'patient', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'reporting', actions: ['create', 'read', 'update', 'delete'] },
  ],
  ROOT: [
    { subject: 'billing', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'dashboard', actions: ['read'] },
    { subject: 'location', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'organization', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'user', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'provider', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'patient', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'reporting', actions: ['create', 'read', 'update', 'delete'] },
    { subject: 'customSurvey', actions: ['create', 'read', 'update', 'delete'] },
  ],
  // Non API-Role-bound
  customSurvey: { subject: 'customSurvey', actions: ['create', 'read', 'update', 'delete'] },
  awv: { subject: 'awv', actions: ['create', 'read', 'update', 'delete'] },
};

export default abilities;
