import { cloneDeep } from 'lodash';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { validateReducerData } from '../../utils/helpers';
import {
  FETCH_FEDERATED_DOMAINS_FAILURE,
  FETCH_FEDERATED_DOMAINS_REQUEST,
  FETCH_FEDERATED_DOMAINS_SUCCESS,
  FORGOT_PASSWORD_CHANGE_FAILURE,
  FORGOT_PASSWORD_CHANGE_SUCCESS,
  NEW_USER_CREDS_FAILURE,
  POP_USER_STATE,
  SET_FORGOT_USER_FAILURE,
  SET_FORGOT_USER_REQUEST,
  SET_FORGOT_USER_SUCCESS,
  SET_NEW_USER,
  SET_PIN_FAILURE,
  SET_PIN_REQUEST,
  SET_PIN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS,
} from '../types/authTypes';
import { SET_INITIAL_STATE } from '../types/genericTypes';

const schema = {
  type: 'object',
  properties: {
    error: { type: ['string', 'null'], optional: true },
    federatedDomains: { type: 'object' },
    forgotuser: { type: ['string', 'null'], optional: true },
    forgotPasswordChanged: { type: 'boolean', optional: true },
    loading: { type: 'boolean' },
    newUser: { type: [CognitoUser, 'null'], optional: true },
    user: {
      type: ['object', 'null'],
      properties: {
        awvRole: { type: ['string', 'null'] },
        emailAddress: { type: 'string' },
        isFederated: { type: 'boolean' },
        isRoot: { type: 'boolean' },
        locations: { type: ['array'] },
        name: { type: 'string' },
        org: { type: 'object' },
        payload: { type: ['object'] },
        token: { type: 'string' },
        pin: { type: ['string', 'null', 'number'] },
      },
    },
    pinError: { type: ['string', 'null'], optional: true },
  },
};

const initialState = {
  error: null,
  federatedDomains: {},
  forgotUser: null,
  forgotPasswordChanged: false,
  loading: false,
  newUser: null,
  user: {
    awvRole: '',
    emailAddress: 'Account',
    isFederated: false,
    isRoot: false,
    locations: [],
    name: '',
    org: {},
    payload: {},
    token: '',
    pin: null,
  },
  pinError: null,
};

const reducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case FETCH_FEDERATED_DOMAINS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_FEDERATED_DOMAINS_REQUEST:
        return { ...state, loading: true, error: null };
    case FETCH_FEDERATED_DOMAINS_SUCCESS:
      return { ...state, loading: false, error: null, federatedDomains: action.payload };

    case FORGOT_PASSWORD_CHANGE_FAILURE:
      return { ...state, error: action.payload };
    case FORGOT_PASSWORD_CHANGE_SUCCESS:
      return { ...state, error: null, forgotPasswordChanged: true, forgotUser: null };

    case NEW_USER_CREDS_FAILURE:
      return { ...state, error: action.payload };

    case SET_INITIAL_STATE:
      return { ...initialState, federatedDomains: state.federatedDomains };

    case SET_FORGOT_USER_FAILURE:
      return { ...state, error: action.payload };
    case SET_FORGOT_USER_REQUEST:
      return { ...state, error: null };
    case SET_FORGOT_USER_SUCCESS:
      return { ...state, error: null, forgotUser: action.payload };

    case SET_PIN_FAILURE:
      return { ...state, pinError: action.error };
    case SET_PIN_REQUEST:
      return { ...state, pinError: null };
    case SET_PIN_SUCCESS:
      return {
        ...state,
        pinError: null,
        user: { ...state.user, pin: action.payload },
      };

    case SET_NEW_USER:
      return { ...state, newUser: action.payload };

    case SIGN_IN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SIGN_IN_REQUEST:
      return { ...state, loading: true, error: null };
    case SIGN_IN_SUCCESS:
      return { ...state, loading: false };

    case SIGN_OUT_FAILURE:
      return { ...initialState, loading: false, error: action.payload };
    case SIGN_OUT_SUCCESS:
      return { ...initialState, appState: action.payload };

    case POP_USER_STATE:
      return { ...state, user: action.payload, error: state.error };

    default:
      return state;
  }
};

export default reducer;
