export const CHECK_FOR_REPORTS_FAILURE = 'CHECK_FOR_REPORTS_FAILURE';
export const CHECK_FOR_REPORTS_REQUEST = 'CHECK_FOR_REPORTS_REQUEST';
export const CHECK_FOR_REPORTS_SUCCESS = 'CHECK_FOR_REPORTS_SUCCESS';
export const CLOSE_REPORTS_MODAL = 'CLOSE_REPORTS_MODAL';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';
export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const EMAIL_REPORT_FAILURE = 'EMAIL_REPORT_FAILURE';
export const EMAIL_REPORT_REQUEST = 'EMAIL_REPORT_REQUEST';
export const EMAIL_REPORT_SUCCESS = 'EMAIL_REPORT_SUCCESS';
export const GET_APPOINTMENTS_FOR_LOCATION_FAILURE = 'GET_APPOINTMENTS_FOR_LOCATION_FAILURE';
export const GET_APPOINTMENTS_FOR_LOCATION_REQUEST = 'GET_APPOINTMENTS_FOR_LOCATION_REQUEST';
export const GET_APPOINTMENTS_FOR_LOCATION_SUCCESS = 'GET_APPOINTMENTS_FOR_LOCATION_SUCCESS';
export const GET_AWV_APPOINTMENT_FAILURE = 'GET_AWV_APPOINTMENT_FAILURE';
export const GET_AWV_APPOINTMENT_REQUEST = 'GET_AWV_APPOINTMENT_REQUEST';
export const GET_AWV_APPOINTMENT_SUCCESS = 'GET_AWV_APPOINTMENT_SUCCESS';
export const GET_LOCATIONS_FOR_AWV_FAILURE = 'GET_LOCATIONS_FOR_AWV_FAILURE';
export const GET_LOCATIONS_FOR_AWV_REQUEST = 'GET_LOCATIONS_FOR_AWV_REQUEST';
export const GET_LOCATIONS_FOR_AWV_SUCCESS = 'GET_LOCATIONS_FOR_AWV_SUCCESS';
export const GET_REPORT_PRESIGNED_URL_FAILURE = 'GET_REPORT_PRESIGNED_URL_FAILURE';
export const GET_REPORT_PRESIGNED_URL_REQUEST = 'GET_REPORT_PRESIGNED_URL_REQUEST';
export const GET_REPORT_PRESIGNED_URL_SUCCESS = 'GET_REPORT_PRESIGNED_URL_SUCCESS';
export const OPEN_REPORTS_MODAL = 'OPEN_REPORTS_MODAL';
export const REMOVE_APPOINTMENTS_FOR_AWV_REQUEST = 'REMOVE_APPOINTMENTS_FOR_AWV_REQUEST';
export const RESET_AWV_APPOINTMENT_MODAL = 'RESET_AWV_APPOINTMENT_MODAL';
export const SELECT_APPOINTMENT_FOR_AWV = 'SELECT_APPOINTMENT_FOR_AWV';
export const SELECT_LOCATION_FOR_AWV_FAILURE = 'SELECT_LOCATION_FOR_AWV_FAILURE';
export const SELECT_LOCATION_FOR_AWV_REQUEST = 'SELECT_LOCATION_FOR_AWV_REQUEST';
export const SELECT_LOCATION_FOR_AWV_SUCCESS = 'SELECT_LOCATION_FOR_AWV_SUCCESS';
export const UPDATE_APPOINTMENT_AND_HRA_VITALS_SUCCESS = 'UPDATE_APPOINTMENT_AND_HRA_VITALS_SUCCESS';
export const UPDATE_ELIGIBILITY_FAILURE = 'UPDATE_ELIGIBILITY_FAILURE';
export const UPDATE_ELIGIBILITY_REQUEST = 'UPDATE_ELIGIBILITY_REQUEST';
export const UPDATE_ELIGIBILITY_SUCCESS = 'UPDATE_ELIGIBILITY_SUCCESS';
export const UPDATE_HRA_VITALS_FAILURE = 'UPDATE_HRA_VITALS_FAILURE';
export const UPDATE_HRA_VITALS_REQUEST = 'UPDATE_HRA_VITALS_REQUEST';
export const UPDATE_HRA_VITALS_SUCCESS = 'UPDATE_HRA_VITALS_SUCCESS';
export const UPDATE_PATIENT_AND_APPOINTMENT_FAILURE = 'UPDATE_PATIENT_AND_APPOINTMENT_FAILURE';
export const UPDATE_PATIENT_AND_APPOINTMENT_REQUEST = 'UPDATE_PATIENT_AND_APPOINTMENT_REQUEST';
export const UPDATE_PATIENT_AND_APPOINTMENT_SUCCESS = 'UPDATE_PATIENT_AND_APPOINTMENT_SUCCESS';
export const UPDATE_PATIENT_FOR_APPOINTMENT_FAILURE = 'UPDATE_PATIENT_FOR_APPOINTMENT_FAILURE';
export const UPDATE_PATIENT_FOR_APPOINTMENT_REQUEST = 'UPDATE_PATIENT_FOR_APPOINTMENT_REQUEST';
export const UPDATE_PATIENT_FOR_APPOINTMENT_SUCCESS = 'UPDATE_PATIENT_FOR_APPOINTMENT_SUCCESS';
export const UPDATE_PROVIDER_FOR_APPOINTMENT_FAILURE = 'UPDATE_PROVIDER_FOR_APPOINTMENT_FAILURE';
export const UPDATE_PROVIDER_FOR_APPOINTMENT_REQUEST = 'UPDATE_PROVIDER_FOR_APPOINTMENT_REQUEST';
export const UPDATE_PROVIDER_FOR_APPOINTMENT_SUCCESS = 'UPDATE_PROVIDER_FOR_APPOINTMENT_SUCCESS';
