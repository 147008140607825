import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { switchSlideoutPanel, switchFixedSlideoutPanel, isAuthenticated } from '../../utils/helpers';
import { AbilityContext } from '../Ability/AbilityContext';
import ability from '../Ability/Ability';

// Containers
const DefaultLayout = React.lazy(() => import('../../containers/DefaultLayout'));
const HraLayout = React.lazy(() => import('../../containers/HraLayout'));

// Pages
const Login = React.lazy(() => import('../../views/Pages/Login'));
const NewUser = React.lazy(() => import('../../views/Pages/NewUser'));
const ForgotPassword = React.lazy(() => import('../../views/Pages/ForgotPassword'));

class RouterComponent extends Component {
  render() {
    const privateRouteProps = {
      component: DefaultLayout,
      path: '/',
    };

    return (
      <Switch>
        <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
        <Route exact path="/newuser" name="Login Page" render={props => <NewUser {...props} />} />
        <Route exact path="/forgotpassword" name="Forgot Password Page" render={props => <ForgotPassword {...props} />} />
        {this.renderPrivateRoute(privateRouteProps)}
      </Switch>
    );
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      switchSlideoutPanel('close');
      switchFixedSlideoutPanel('close');
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  renderPrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const { auth } = this.props;
        const { location } = props;
        const { pin, id, isRoot } = auth.user;
        const redirectToPinSelect = !props.location?.pathname.includes('/pin-select') && !pin && id;

        if (isAuthenticated()) {
          // TODO remove auth check when we implement email links for surveys
          if (location.pathname.includes('/hra')) {
            return <HraLayout />;
          }

          if (redirectToPinSelect) {
            return (
              <Redirect
                to={{
                  pathname: '/pin-select',
                  state: { from: location },
                }}
              />
            );
          }

          if (isMobile) {
            if (
              pin
              && !isRoot
              && !location.pathname.includes('/awv')
            ) {
              return (
                <Redirect
                  to={{
                    pathname: '/awv',
                    state: { from: location },
                  }}
                />
              );
            }
          }
        }

        return (isAuthenticated() ? (
          <AbilityContext.Provider value={ability}>
            <Component {...props} />
          </AbilityContext.Provider>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        ));
      }}
    />
  );
}

RouterComponent.propTypes = {
  auth: PropTypes.object,
  history: PropTypes.object,
};


export default connect(
  ({ auth }) => ({
    auth,
  }),
  null,
)(RouterComponent);
