import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CLEAR_LOCATION_EDIT_FORM_ERROR,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  LOCATIONS_SCOPEFILTER_UPDATE,
  RESET_LOCATION_CREATE_FORM,
  RESET_LOCATION_EDIT_FORM,
  UPDATE_LOCATION_CREATE_FORM,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_EDIT_FORM, CREATE_LOCATION_SUCCESS,
} from '../types/locationsTypes';

const schema = {
  type: 'object',
  properties: {
    createForm: {
      type: 'object',
      properties: {
        error: { type: ['string', 'null'], optional: true },
        values: {
          type: 'object',
          properties: {
            city: { type: 'string' },
            description: { type: ['string', 'null'] },
            fax: { type: ['string', 'null'] },
            integrationType: { type: ['string', 'null'] },
            name: { type: 'string' },
            tin: { type: ['number', 'null', 'string'] },
            office: { type: ['string', 'null'] },
            orgID: { type: 'string' },
            state: { type: 'string', optional: true },
            streetAddress: { type: 'string' },
            zip: { type: 'string' },
          },
        },
      },
    },
    data: { type: ['object', 'null'] },
    editForm: {
      type: 'object',
      properties: {
        error: { type: ['string', 'null'], optional: true },
        values: {
          type: 'object',
          properties: {
            city: { type: 'string' },
            description: { type: ['string', 'null'] },
            fax: { type: ['string', 'null'] },
            id: { type: 'string' },
            integrationType: { type: ['string', 'null'] },
            isActive: { type: 'boolean' },
            name: { type: 'string' },
            tin: { type: ['number', 'null', 'string'] },
            office: { type: ['string', 'null'] },
            state: { type: 'string', optional: true },
            streetAddress: { type: 'string' },
            zip: { type: 'string' },
          },
        },
      },
    },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: { type: ['object'], optional: true },
  },
};

const initialState = {
  createForm: {
    error: null,
    values: {
      city: '',
      description: null,
      fax: null,
      integrationType: null,
      name: '',
      tin: null,
      office: null,
      orgID: '',
      state: '',
      streetAddress: '',
      zip: '',
    },
  },
  data: null,
  editForm: {
    error: null,
    values: {
      city: '',
      description: null,
      fax: null,
      id: '',
      integrationType: null,
      isActive: false,
      name: '',
      tin: null,
      office: null,
      state: '',
      streetAddress: '',
      zip: '',
    },
  },
  error: null,
  loading: false,
  scopeFilter: {},
};

const locationsReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CLEAR_LOCATION_EDIT_FORM_ERROR: {
      return { ...state, editForm: { ...state.editForm, error: null } };
    }

    case CREATE_LOCATION_SUCCESS: {
      const newLocs = state.data;
      newLocs[action.payload.id] = action.payload;

      return { ...state, data: { ...state.data, ...newLocs } };
    }

    case FETCH_LOCATIONS_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_LOCATIONS_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case FETCH_LOCATIONS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }

    case LOCATIONS_SCOPEFILTER_UPDATE: {
      return { ...state, scopeFilter: { organizations: action.organizations } };
    }

    case RESET_LOCATION_CREATE_FORM: {
      return { ...state, loading: false, createForm: initialState.createForm };
    }

    case RESET_LOCATION_EDIT_FORM: {
      return { ...state, loading: false, editForm: initialState.editForm };
    }

    case UPDATE_LOCATION_CREATE_FORM: {
      return { ...state, loading: false, createForm: action.payload, error: null };
    }

    case UPDATE_LOCATION_EDIT_FORM: {
      return { ...state, loading: false, editForm: action.payload, error: null };
    }

    case UPDATE_LOCATION_FAILURE: {
      const newEditForm = state.editForm;
      newEditForm.error = action.error;

      return { ...state, loading: false, editForm: newEditForm };
    }
    case UPDATE_LOCATION_SUCCESS: {
      const newLocs = state.data;
      newLocs[action.payload.id] = { ...newLocs[action.payload.id], ...action.payload };

      return { ...state, data: { ...state.data, ...newLocs } };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default locationsReducer;
