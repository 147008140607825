import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import React, { Component } from 'react';
import * as ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router-dom';

import Spinner from './components/Spinner';
import AmplifyBridge from './modules/AmplifyBridge';
import configureStore, { history } from './modules/configureStore';
import FontAwesomeLoader from './modules/FontAwesomeLoader';
import Router from './modules/router/Router';
import { configureAmplify } from './utils/cognito';
import routes from './routes';
import './App.scss';

Sentry.init({
  autoSessionTracking: true,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
    }),
  ],
  release: `cortes-web@${process.env.REACT_APP_PACKAGE_VERSION}`,
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
});

// Google Analytics setup
if (
  process.env.REACT_APP_UA_CODE &&
  (
    process.env.REACT_APP_FORCE_ANALYTICS === 'true' ||
    process.env.NODE_ENV === 'production'
  )
) {
  ReactGA.initialize(process.env.REACT_APP_UA_CODE);
  ReactGA.set({ dimension3: process.env.NODE_ENV });
}

// Redux store creation
const initialState = {};
const store = configureStore(initialState);

// Config & Instantiation
configureAmplify();
new AmplifyBridge(store);
new FontAwesomeLoader();


class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <React.Suspense fallback={this.renderLoading()}>
              <Router history={history} />
            </React.Suspense>
          </ConnectedRouter>
        </Provider>
      </Sentry.ErrorBoundary>
    );
  }

  renderLoading = () => <Spinner />;
}

export default App;
