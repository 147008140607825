import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const checkCircle = <FontAwesomeIcon icon={['fad', 'check-circle']} size="lg" className="text-success" />;
export const doNotEnter = <FontAwesomeIcon icon={['fad', 'do-not-enter']} size="lg" className="text-danger" />;
export const emptyCircle = <FontAwesomeIcon icon={['fad', 'scrubber']} size="lg" className="text-success" />;

export const overlayFormatter = cell => (
  <React.Fragment>
    {cell}
    <div className="aside-table-gradient" />
  </React.Fragment>
);
