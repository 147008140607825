import { cloneDeep } from 'lodash';

import {
  AUTHENTICATE_PIN_FAILURE,
  AUTHENTICATE_PIN_REQUEST,
  AUTHENTICATE_PIN_SUCCESS,
  CLEAR_SELECTED_USER_REQUEST,
  FETCH_USERS_FOR_PIN_SELECT_FAILURE,
  FETCH_USERS_FOR_PIN_SELECT_REQUEST,
  FETCH_USERS_FOR_PIN_SELECT_SUCCESS,
  LOCKED_FOR_PIN,
  SELECT_USER_FAILURE,
  SELECT_USER_REQUEST,
  SELECT_USER_SUCCESS,
} from '../types/pinTypes';
import { POP_USER_STATE } from '../types/authTypes';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';

const schema = {
  type: 'object',
  properties: {
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    locked: { type: 'boolean' },
    selectedUserID: { type: ['string', 'null'] },
    users: { type: ['object'] },
  },
};

const initialState = {
  loading: false,
  error: null,
  locked: false,
  selectedUserID: null,
  users: {},
};

const pinReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case AUTHENTICATE_PIN_FAILURE:
      return { ...state, loading: false, error: action.error };
    case AUTHENTICATE_PIN_REQUEST:
        return { ...state, loading: true, error: null };
    case AUTHENTICATE_PIN_SUCCESS:
      return { ...state, loading: false, error: null, locked: !action.valid };

    case CLEAR_SELECTED_USER_REQUEST:
      return { ...state, selectedUserID: null };

    case FETCH_USERS_FOR_PIN_SELECT_FAILURE:
      return { ...state, loading: false, error: action.error };
    case FETCH_USERS_FOR_PIN_SELECT_REQUEST:
        return { ...state, loading: true, error: null };
    case FETCH_USERS_FOR_PIN_SELECT_SUCCESS:
      return { ...state, loading: false, error: null, users: action.payload };

    case LOCKED_FOR_PIN:
      return { ...state, locked: action.payload };

    case SELECT_USER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case SELECT_USER_REQUEST:
        return { ...state, loading: true, error: null };
    case SELECT_USER_SUCCESS:
      return { ...state, loading: false, error: null, selectedUserID: action.payload };

    case POP_USER_STATE:
      return { ...state, selectedUserID: action.payload.id };

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default pinReducer;
