export const FETCH_FEDERATED_DOMAINS_FAILURE = 'FETCH_FEDERATED_DOMAINS_FAILURE';
export const FETCH_FEDERATED_DOMAINS_REQUEST = 'FETCH_FEDERATED_DOMAINS_REQUEST';
export const FETCH_FEDERATED_DOMAINS_SUCCESS = 'FETCH_FEDERATED_DOMAINS_SUCCESS';
export const FORGOT_PASSWORD_CHANGE_FAILURE = 'FORGOT_PASSWORD_CHANGE_FAILURE';
export const FORGOT_PASSWORD_CHANGE_SUCCESS = 'FORGOT_PASSWORD_CHANGE_SUCCESS';
export const NEW_USER_CREDS_FAILURE = 'NEW_USER_CREDS_FAILURE';
export const POP_USER_STATE = 'POP_USER_STATE';
export const SET_FORGOT_USER_FAILURE = 'SET_FORGOT_USER_FAILURE';
export const SET_FORGOT_USER_REQUEST = 'SET_FORGOT_USER_REQUEST';
export const SET_FORGOT_USER_SUCCESS = 'SET_FORGOT_USER_SUCCESS';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_PIN_FAILURE = 'SET_PIN_FAILURE';
export const SET_PIN_REQUEST = 'SET_PIN_REQUEST';
export const SET_PIN_SUCCESS = 'SET_PIN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';
export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
