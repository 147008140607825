import { API, graphqlOperation } from 'aws-amplify';
import S3 from 'aws-sdk/clients/s3';
import {
  each,
  filter,
  find,
  forEach,
  get,
  map,
  pullAt,
  range,
  set,
  sum,
} from 'lodash';
import { normalize, schema } from 'normalizr';

import {
  listHras,
  listHrasByOrg,
  listHrasByOrgForRevenueMtd,
  listHrasForRevenueMtd,
  listSurveyVersions,
} from '../graphql/queries';
import { riskCategories } from '../views/Reporting/RiskInterventions/riskCategories';
import { MAIN_HRA_SURVEYID } from '../utils/constants';
import HraHelper from './HraHelper';

export const fetchHras = async (
  scopeFilter = {},
  { startDate, endDate },
  isBilled = '',
  isRoot = false,
  orgID = null,
  forRevenueMtd = false,
) => {
  let keyedResults;
  const hrasSchema = [new schema.Entity('hras')];

  // start building the query filter
  const filter = { and: [{ isComplete: { eq: true } }] };

  if (isBilled !== 'all') {
    filter.and.push({ isBilled: { eq: isBilled === 'yes' } });
  }

  // build out `filter` based on scopefilter parameters
  if (scopeFilter.providers) {
    filter.and.push({ providerID: { eq: scopeFilter.providers } });
  } else if (scopeFilter.locations) {
    filter.and.push({ locationID: { eq: scopeFilter.locations } });
  } else if (scopeFilter.organizations) {
    filter.and.push({ orgID: { eq: scopeFilter.organizations } });
  }

  // build dateTime filter portion
  let dateTime;
  if (startDate && endDate) {
    dateTime = { between: [startDate.format(), endDate.format()] };
  } else if (startDate) {
    dateTime = { ge: startDate.format() };
  } else if (endDate) {
    dateTime = { le: endDate.format() };
  }

  if (isRoot) {
    // select the GQL query to use
    const query = forRevenueMtd ? listHrasForRevenueMtd : listHras;

    if (dateTime) {
      filter.and.push({ dateTime });
    }

    const queryVars = { filter, limit: 10000 };
    const listHrasResults = await pageGqlOp(
      query,
      queryVars,
      'listHras.items',
      'listHras.nextToken',
    );
    keyedResults = normalize(listHrasResults, hrasSchema);
  } else {
    // select the GQL query to use
    const query = forRevenueMtd ? listHrasByOrgForRevenueMtd : listHrasByOrg;

    const queryVars = { filter, orgID, limit: 10000 };
    if (dateTime) {
      queryVars.dateTime = dateTime;
    }
    const listHrasByOrgResults = await pageGqlOp(
      query,
      queryVars,
      'listHrasByOrg.items',
      'listHrasByOrg.nextToken',
    );
    keyedResults = normalize(listHrasByOrgResults, hrasSchema);
  }

  return keyedResults;
};

/**
 * Issues and pages over a given GQL query using its returned `nextToken` if available.
 *
 * @param {string} query - The GQL query to run
 * @param {Object} queryVars - A variables object to use for the query
 * @param {string} [responsePath] - The object path to retreive the response at, excluding a `data` prefix
 * @param {string} [nextTokenName="nextToken"] - The query variable name to provid the nextToken at
 * @param {string} [concatIntoPath=null] - An optional path to set the concatenated data onto
 *
 * @returns {Array|Object} - A concatenated array of results from the `responsePath`. Will alternatively be
 * an object containing the concatendated array if `concatIntoPath` is passed.
 */
export const pageGqlOp = async (
  query,
  queryVars,
  responsePath,
  nextTokenPath,
  nextTokenName = 'nextToken',
  concatIntoPath = null,
) => {
  let graphResponse = await API.graphql(graphqlOperation(query, queryVars));
  let results = get(graphResponse.data, responsePath);

  while (get(graphResponse.data, nextTokenPath)) {
    const nextToken = get(graphResponse.data, nextTokenPath);
    queryVars[nextTokenName] = nextToken;

    // eslint-disable-next-line no-await-in-loop
    graphResponse = await API.graphql(graphqlOperation(query, queryVars));
    results = results.concat(get(graphResponse.data, responsePath));
  }

  if (concatIntoPath) {
    let parentResults = get(graphResponse.data, concatIntoPath);
    const trimmedPath = responsePath.substring(responsePath.indexOf('.') + 1);
    parentResults = set(parentResults, trimmedPath, results);

    return parentResults;
  }

  return results;
};

export const riskMatrixCalculator = async (hras) => {
  // build empty matrix frame
  const matrix = [];
  forEach(riskCategories, (cat) => {
    matrix.push({
      index: cat.index,
      name: cat.name,
      triggers: {
        primary: [],
        secondary: [],
        tertiary: [],
      },
    });
  });

  // fetch HRA master from S3
  const hrajsonS3Params = {
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: 'hra/questions/HRA-questions-en.json',
  };
  const s3 = new S3();
  const s3Resp = await s3.getObject(hrajsonS3Params).promise();
  const hraMaster = JSON.parse(s3Resp.Body.toString());

  // retreive all surveyVersion records for main HRA Survey
  const listSurveyVersionsResp = await API.graphql(
    graphqlOperation(listSurveyVersions, {
      filter: { surveyID: { eq: MAIN_HRA_SURVEYID } },
      limit: 10000,
    }),
  );
  const mainHraSurveyVersions =
    listSurveyVersionsResp.data.listSurveyVersions.items;

  // filter out HRAs without vitals
  const hrasWithVitals = filter(
    hras,
    item => item.vitals || item.vitalsOptOut,
  );

  // format HRA records and fill matrix
  forEach(hrasWithVitals, (hra) => {
    const { patient, surveyQas, vitalsOptOut } = hra;

    // this check prevents running this on the oldest HRA records created prior to
    // the introduction of survey versioning
    if (!hra.qas && surveyQas) {
      let mainHraSurveyQas;
      each(surveyQas, (surveyQasItem, index) => {
        // identify and pull the "main" `surveyQas` from current (looped) HRA record by iterating
        // over all `mainHraSurveyVersions` and looking for a match on `surveyVersionID`
        if (
          find(mainHraSurveyVersions, [
            'id',
            surveyQasItem.surveyVersionID,
          ]) !== undefined
        ) {
          mainHraSurveyQas = pullAt(surveyQas, index);
          return false; // exit iteration early by explicitly returning false
        }
      });

      if (!mainHraSurveyQas) {
        throw new Error(
          'None of the surveyQas on the HRA object matched a main survey version ID.',
        );
      }

      // assign the main hra surveyQas to the hra record
      hra.qas = mainHraSurveyQas[0].qas;
    }

    // populate risk matrix
    const hh = new HraHelper(hraMaster, hra);
    const vitals = hra.vitals || {};
    let tt;

    if (!vitalsOptOut) {
      if (!hh.rspIncs(11, 20) && hh.rspIncs(10, 20)) {
        tt = 'primary';
      } else if (
        hh.rspIncs(11, 20) &&
        (vitals.bpSystolic > 160 || vitals.bpDiastolic > 100)
      ) {
        tt = 'tertiary';
      } else if (hh.rspIncs(11, 20)) {
        tt = 'secondary';
      }
      if (tt) {
        matrix[0].triggers[tt].push(patient);
        tt = undefined;
      }
    }

    if (!hh.rspIncs(11, 18) && hh.rspIncs(10, 18)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 18)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[1].triggers[tt].push(patient);
      tt = undefined;
    }

    if (!hh.rspIncs(11, 26) && hh.rspIncs(10, 26)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 26) && !hh.rspIncs(15, 0) && !hh.rspIncs(15, 4)) {
      tt = 'tertiary';
    } else if (hh.rspIncs(11, 26)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[2].triggers[tt].push(patient);
      tt = undefined;
    }

    if (!vitalsOptOut) {
      if (!hh.rspIncs(11, 29) && hh.rspIncs(10, 29)) {
        tt = 'primary';
      } else if (
        hh.rspIncs(11, 29) &&
        !vitalsOptOut &&
        (vitals.bpSystolic > 160 || vitals.bpDiastolic > 100)
      ) {
        tt = 'tertiary';
      } else if (hh.rspIncs(11, 29)) {
        tt = 'secondary';
      }
      if (tt) {
        matrix[3].triggers[tt].push(patient);
        tt = undefined;
      }
    }

    if (hh.rspIncs(15, 0) || hh.rspIncs(15, 4)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 26) || hh.rspIncs(11, 20)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[4].triggers[tt].push(patient);
      tt = undefined;
    }

    if (!hh.rspIncs(11, 5) && hh.rspIncs(10, 5)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 5) && hh.rspIncs(36, 0)) {
      tt = 'tertiary';
    } else if (hh.rspIncs(11, 5)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[5].triggers[tt].push(patient);
      tt = undefined;
    }

    if (hh.rspIncs(36, 1) && hh.rspIncs(37, 0)) {
      tt = 'primary';
    } else if (!hh.rspIncs(37, 0)) {
      tt = 'secondary';
    } else if (!hh.rspIncs(36, 1)) {
      tt = 'tertiary';
    }
    if (tt) {
      matrix[6].triggers[tt].push(patient);
      tt = undefined;
    }

    const phqScore = sum(
      map(range(48, 57), (qID) => {
        const resp = hh.getRsp(qID);
        return resp ? resp[0] : 0;
      }),
    );
    if (!hh.rspIncs(11, 17) && hh.rspIncs(10, 17)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 17) && phqScore > 15) {
      tt = 'tertiary';
    } else if (hh.rspIncs(11, 17)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[7].triggers[tt].push(patient);
      tt = undefined;
    }

    if (!hh.rspIncs(11, 1) && hh.rspIncs(10, 1)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 1)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[8].triggers[tt].push(patient);
      tt = undefined;
    }

    if (!hh.rspIncs(11, 16) && hh.rspIncs(10, 16)) {
      tt = 'primary';
    } else if (hh.rspIncs(11, 16) || hh.rspIncs(11, 29)) {
      tt = 'secondary';
    }
    if (tt) {
      matrix[9].triggers[tt].push(patient);
      tt = undefined;
    }

    if (!vitalsOptOut) {
      if (!hh.rspIncs(11, 21) && hh.rspIncs(10, 21)) {
        tt = 'primary';
      } else if (
        hh.rspIncs(11, 21) &&
        !vitalsOptOut &&
        (vitals.bpSystolic > 160 || vitals.bpDiastolic > 100)
      ) {
        tt = 'tertiary';
      } else if (hh.rspIncs(11, 21)) {
        tt = 'secondary';
      }
      if (tt) {
        matrix[10].triggers[tt].push(patient);
        tt = undefined;
      }
    }
  });

  return matrix;
};
