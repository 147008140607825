import { cloneDeep, isUndefined } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CLEAR_PROVIDER_EDIT_FORM_ERROR,
  CREATE_PROVIDER_FAILURE,
  CREATE_PROVIDER_REQUEST,
  CREATE_PROVIDER_SUCCESS,
  FETCH_PROVIDER_LOCATIONS_SUCCESS,
  GET_PROVIDERS_FAILURE,
  GET_PROVIDERS_REQUEST,
  GET_PROVIDERS_SUCCESS,
  UPDATE_PROVIDER_FAILURE,
  UPDATE_PROVIDERS_DATA,
  UPDATE_PROVIDERS_SCOPEFILTER,
} from '../types/providersTypes';

const schema = {
  type: 'object',
  properties: {
    data: { type: ['object'] },
    createError: { type: ['string', 'null'], optional: true },
    editError: { type: ['string', 'null'], optional: true },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: { type: ['object'], optional: true },
  },
};

const initialState = {
  data: {},
  createError: null,
  editError: null,
  error: null,
  loading: true,
  scopeFilter: {},
};

const providersReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CLEAR_PROVIDER_EDIT_FORM_ERROR: {
      return { ...state, editError: null };
    }

    case CREATE_PROVIDER_FAILURE: {
      return { ...state, loading: false, createError: action.error };
    }
    case CREATE_PROVIDER_REQUEST: {
      return { ...state, createError: null };
    }
    case CREATE_PROVIDER_SUCCESS: {
      const newProviders = state.data;
      newProviders[action.payload.id] = action.payload;

      return { ...state, data: { ...state.data, ...newProviders } };
    }

    case FETCH_PROVIDER_LOCATIONS_SUCCESS: {
      const newProviders = state.data;
      if (isUndefined(newProviders[action.providerID].locations)) {
        newProviders[action.providerID].locations = {};
      }
      newProviders[action.providerID].locations.items = action.payload;

      return { ...state, data: { ...state.data, ...newProviders } };
    }

    case GET_PROVIDERS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_PROVIDERS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_PROVIDERS_SUCCESS: {
      return { ...state, loading: false, data: action.payload, error: null };
    }

    case UPDATE_PROVIDER_FAILURE: {
      return { ...state, loading: false, editError: action.error };
    }

    case UPDATE_PROVIDERS_DATA: {
      const newProviders = state.data;
      newProviders[action.payload.id] = { ...newProviders[action.payload.id], ...action.payload };

      return {
        ...state,
        data: {
          ...state.data,
          ...newProviders,
        },
      };
    }

    case UPDATE_PROVIDERS_SCOPEFILTER: {
      return {
        ...state,
        scopeFilter: {
          organizations: action.organizations,
          locations: action.locations,
        },
      };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default providersReducer;
