import { Logger } from 'aws-amplify';
import {
  isString,
  isArray,
  isEmpty,
  find,
} from 'lodash';

const logger = new Logger('ServiceError');

export default class ServiceError {
  constructor(payload, prefix = null) {
    this.error = null;
    this.payload = payload;
    this.prefix = prefix ? `[${prefix}] ` : '';

    try {
      if (isString(payload)) {
        this.error = payload;
      } else if (
        isArray(payload.errors) &&
        !isEmpty(payload.errors) &&
        find(payload.errors, 'message') !== 'undefined'
      ) {
        this.error = payload.errors[0].message;
        logger.info(this.toString());
      } else if (payload instanceof Error) {
        this.error = `${payload.name}: ${payload.message}`;
      } else {
        logger.error(`ServiceError failed to parse an error: ${this.payload}`);
      }
    } catch (error) {
      this.error = `Unknown error: ${error}`;
    }
  }

  toString() {
    return this.prefix + this.error;
  }
}
