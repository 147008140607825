import * as Sentry from '@sentry/react';
import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import _ from 'lodash';
import { normalize, schema } from 'normalizr';

import {
  authenticatePin as authenticatePinQuery,
  getLocationWithUsersForPinSelect,
  listOrgAdminsByOrg,
} from '../../graphql/queries';
import ServiceError from '../ServiceError';
import { pageGqlOp } from '../sharedMethods';
import {
  AUTHENTICATE_PIN_FAILURE,
  AUTHENTICATE_PIN_REQUEST,
  AUTHENTICATE_PIN_SUCCESS,
  CLEAR_SELECTED_USER_REQUEST,
  FETCH_USERS_FOR_PIN_SELECT_FAILURE,
  FETCH_USERS_FOR_PIN_SELECT_REQUEST,
  FETCH_USERS_FOR_PIN_SELECT_SUCCESS,
  LOCKED_FOR_PIN,
  SELECT_USER_FAILURE,
  SELECT_USER_REQUEST,
  SELECT_USER_SUCCESS,
} from '../types/pinTypes';

export const authenticatePin = (id, pin) => async (dispatch) => {
  dispatch({ type: AUTHENTICATE_PIN_REQUEST });

  try {
    const graphResponse = await API.graphql(graphqlOperation(authenticatePinQuery, { id, pin }));

    return dispatch({
      type: AUTHENTICATE_PIN_SUCCESS,
      valid: graphResponse.data.authenticatePin,
    });
  } catch (error) {
    return dispatch({
      type: AUTHENTICATE_PIN_FAILURE,
      payload: new ServiceError(error, 'Query: authenticatePin').toString(),
    });
  }
};

export const clearSelectedUser = () => (dispatch, getState) => {
  dispatch({ type: CLEAR_SELECTED_USER_REQUEST });

  // Set user details for Sentry.io
  const { id, orgID, awvRole } = getState().auth.user;
  Sentry.setUser({
    id,
    orgID,
    awvRole,
  });
};

export const fetchUsersForPinSelect = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_USERS_FOR_PIN_SELECT_REQUEST });

  const usersSchema = [new schema.Entity('users')];

  try {
    const { user: { org: { id: orgID } } } = getState().auth;
    const { selectedLocationID } = getState().awv;

    // fetch all org admins
    const orgAdmins = await pageGqlOp(
      listOrgAdminsByOrg,
      {
        orgID,
        filter: {
          isActive: { eq: true },
          awvRole: { eq: 'ORG_ADMIN' },
        },
        limit: 500,
      },
      'listUsersByOrg.items',
      'listUsersByOrg.nextToken',
    );

    // fetch users for selected location
    let locationUsers = await pageGqlOp(
      getLocationWithUsersForPinSelect,
      {
        id: selectedLocationID,
        limit: 500,
      },
      'getLocation.users.items',
      'getLocation.users.nextToken',
    );
    locationUsers = _.map(locationUsers, item => ({ ...item.user }));

    let userList = [
      // users assigned to currently selected location
      ...locationUsers,
      // org admins
      ...orgAdmins,
    ];

    // remove duplicates
    userList = _.uniqBy(userList, 'id');

    // filter users with no pin
    userList = _.filter(userList, user => user.pin);

    dispatch({
      type: FETCH_USERS_FOR_PIN_SELECT_SUCCESS,
      payload: normalize(userList, usersSchema).entities.users,
    });
  } catch (error) {
    dispatch({
      type: FETCH_USERS_FOR_PIN_SELECT_FAILURE,
      error: new ServiceError(error).toString(),
    });
  }
 };

export const lockForPin = () => dispatch =>
  dispatch({ type: LOCKED_FOR_PIN, payload: true });

export const selectUser = userID => (dispatch, getState) => {
  dispatch({ type: SELECT_USER_REQUEST });

  const user = getState().pin.users[userID];

  try {
    // Set user details for Sentry.io
    const { id, orgID, awvRole } = user;
    Sentry.setUser({
      id,
      orgID,
      awvRole,
    });

    return dispatch({
      type: SELECT_USER_SUCCESS,
      payload: userID,
    });
  } catch (error) {
    return dispatch({
      type: SELECT_USER_FAILURE,
      error: new ServiceError(error).toString(),
    });
  }
};
