export const GET_HRAS_FAILURE = 'GET_HRAS_FAILURE';
export const GET_HRAS_REQUEST = 'GET_HRAS_REQUEST';
export const GET_HRAS_SUCCESS = 'GET_HRAS_SUCCESS';
export const UPDATE_BILLING_SCOPEFILTER = 'UPDATE_BILLING_SCOPEFILTER';
export const UPDATE_BILLING_STATUS_FAILURE = 'UPDATE_BILLING_SUBMITTING_FAILURE';
export const UPDATE_BILLING_STATUS_REQUEST = 'UPDATE_BILLING_SUBMITTING_REQUEST';
export const UPDATE_BILLING_STATUS_SUCCESS = 'UPDATE_BILLING_SUBMITTING_SUCCESS';
export const RESEND_REPORTS_FAILURE = 'RESEND_REPORTS_FAILURE';
export const RESEND_REPORTS_REQUEST = 'RESEND_REPORTS_REQUEST';
export const RESEND_REPORTS_SUCCESS = 'RESEND_REPORTS_SUCCESS';
