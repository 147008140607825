import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import { FETCH_LOCATIONS_REQUEST } from '../types/locationsTypes';
import {
  FETCH_PROVIDER_LOCATIONS_FAILURE,
  FETCH_PROVIDER_LOCATIONS_REQUEST,
  FETCH_PROVIDER_LOCATIONS_SUCCESS,
} from '../types/providersTypes';
import { SWITCH_SLIDEOUT } from '../types/slideoutTypes';
import {
  GET_USER_FOR_SLIDEOUT_FAILURE,
  GET_USER_FOR_SLIDEOUT_REQUEST,
  GET_USER_FOR_SLIDEOUT_SUCCESS,
} from '../types/usersTypes';

const schema = {
  type: 'object',
  properties: {
    loading: { type: 'boolean' },
    content: {
      type: ['object'],
      properties: {
        fixed: { type: 'boolean', optional: true },
        module: { type: ['string', 'null'] },
        userId: { type: 'string', optional: true },
      },
    },
    error: {
      type: ['string', 'null'],
      optional: true,
    },
  },
};

const initialState = {
  loading: false,
  content: {
    module: null,
  },
  error: null,
};

const slideoutReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case FETCH_LOCATIONS_REQUEST: {
      return { ...state, loading: true };
    }

    case FETCH_PROVIDER_LOCATIONS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case FETCH_PROVIDER_LOCATIONS_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_PROVIDER_LOCATIONS_SUCCESS: {
      return { ...state, loading: false, error: null };
    }

    case GET_USER_FOR_SLIDEOUT_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_USER_FOR_SLIDEOUT_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_USER_FOR_SLIDEOUT_SUCCESS: {
      return { ...state, loading: false, error: null };
    }

    case SWITCH_SLIDEOUT: {
      return { ...state, loading: false, content: action.payload, error: null };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default slideoutReducer;
