import * as ReactGA from 'react-ga';

let currentPage;

// eslint-disable-next-line no-unused-vars
export const googleAnalytics = store => next => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

    if (currentPage !== nextPage) {
      currentPage = nextPage;
      ReactGA.pageview(nextPage);
    }
  }

  return next(action);
};
