export const CREATE_LOCATION_REQUEST = 'CREATE_LOCATION_REQUEST';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';
export const CLEAR_LOCATION_EDIT_FORM_ERROR = 'CLEAR_LOCATION_EDIT_FORM_ERROR';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const LOCATIONS_SCOPEFILTER_UPDATE = 'LOCATIONS_SCOPEFILTER_UPDATE';
export const RESET_LOCATION_CREATE_FORM = 'RESET_LOCATION_CREATE_FORM';
export const RESET_LOCATION_EDIT_FORM = 'RESET_LOCATION_EDIT_FORM';
export const UPDATE_LOCATION_CREATE_FORM = 'UPDATE_LOCATION_CREATE_FORM';
export const UPDATE_LOCATION_EDIT_FORM = 'UPDATE_LOCATION_EDIT_FORM';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';
export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
