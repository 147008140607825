import {
  find,
  includes,
  pick,
  values,
} from 'lodash';

export default class HraHelper {
  /**
   * @param {Object} master - The HRA master JSON Q&A list
   * @param {Object} response - An instance of the `Hra` object from the GQL service
   */
  constructor(master, response) {
    this.master = master;
    this.qas = response.qas;
  }

  /**
   * Retrieves one or more answer values from the master Q&A list.
   *
   * @param {number} qId - The question ID on the master list
   * @param {number[]} aIds - One or more answer IDs to retrieve
   * @param {boolean} [getIntVal=false] - Whether to return the integer value of the answer. Works w/ single
   *                                      `aIds` only.
   * @returns {(Array|string|number)}
   */
  getMA(qId, aIds, getIntVal = false) {
    if (aIds.length > 1) {
      return values(pick(this.master[qId].answers, aIds));
    }

    const answer = this.master[qId].answers[aIds[0]];

    return getIntVal ? parseInt(answer, 10) : answer;
  }

  /**
   * Retrieves a question's text from the master Q&A list.
   *
   * @param {number} qId - The question ID on the master list
   * @returns {string}
   */
  getMQ(qId) {
    return this.master[qId].q;
  }

  /**
   * Retreives answer ID(s) from the `Hra` object.
   *
   * @param {number} qId - The question ID in the `Hra` object
   * @returns {?number[]}
   */
  getRsp(qId) {
    const qasEntry = find(this.qas, ['qId', qId]);

    return qasEntry !== undefined ? qasEntry.aIds.sort() : null;
  }

  /**
   * Discovers whether a given answer ID exists in an `Hra.qas` object.
   *
   * @param {number} qId - The question ID in the `Hra` object
   * @param {number} aId - An answer ID from the master list
   * @returns {boolean}
   */
  rspIncs(qId, aId) {
    return includes(this.getRsp(qId), aId);
  }
}
