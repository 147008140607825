import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CLEAR_USER_EDIT_FORM_ERROR,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  GET_USERS_FAILURE,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  RESET_TEMPORARY_PASSWORD_FAILURE,
  RESET_TEMPORARY_PASSWORD_REQUEST,
  RESET_TEMPORARY_PASSWORD_SUCCESS,
  RESET_USER_CREATE_FORM,
  UPDATE_USER_EDIT_FORM_LOCATIONS,
  UPDATE_USER_EDIT_FORM,
  UPDATE_USER_FAILURE,
  UPDATE_USERS_DATA,
  UPDATE_USERS_SCOPEFILTER,
} from '../types/usersTypes';

const schema = {
  type: 'object',
  properties: {
    createForm: {
      type: 'object',
      properties: {
        error: { type: ['string', 'null'], optional: true },
      },
    },
    data: {
      type: ['object', 'null'],
      optional: true,
    },
    editForm: {
      type: 'object',
      properties: {
        error: { type: ['string', 'null'], optional: true },
        originalLocationUserIds: { type: 'object' },
        resetSubmitting: { type: 'boolean', optional: true },
        selectedLocations: { type: 'array' },
        successMessage: { type: ['string', 'null'], optional: true },
        values: {
          type: 'object',
          properties: {
            awvRole: { type: ['string', 'null'] },
            emailAddress: { type: 'string' },
            firstName: { type: 'string' },
            id: { type: 'string' },
            isActive: { type: 'boolean' },
            lastName: { type: 'string' },
          },
        },
        loading: { type: 'boolean', optional: true },
      },
    },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: {
      type: ['object'],
      optional: true,
    },
  },
};

const initialState = {
  loading: false,
  createForm: {
    error: null,
  },
  data: null,
  editForm: {
    error: null,
    originalLocationUserIds: {},
    selectedLocations: [],
    resetSubmitting: false,
    values: {
      awvRole: '',
      emailAddress: '',
      firstName: '',
      id: '',
      isActive: true,
      lastName: '',
    },
    loading: false,
  },
  error: null,
  scopeFilter: {},
  successMessage: null,
};

const usersReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CLEAR_USER_EDIT_FORM_ERROR: {
      return { ...state, editForm: { ...state.editForm, error: null } };
    }

    case CREATE_USER_FAILURE: {
      const newCreateForm = state.createForm;
      newCreateForm.error = action.error;

      return { ...state, createForm: newCreateForm };
    }
    case CREATE_USER_REQUEST: {
      return { ...state, error: null };
    }
    case CREATE_USER_SUCCESS: {
      const newUsers = state.data;
      newUsers[action.payload.id] = action.payload;

      return { ...state, data: { ...state.data, ...newUsers } };
    }

    case GET_USERS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_USERS_SUCCESS: {
      return { ...state, loading: false, data: action.payload, error: null };
    }
    case GET_USERS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }

    case UPDATE_USERS_DATA: {
      const newUsers = state.data;
      newUsers[action.payload.id] = { ...newUsers[action.payload.id], ...action.payload };

      return {
        ...state,
        data: {
          ...state.data,
          ...newUsers,
        },
      };
    }

    case UPDATE_USER_FAILURE: {
      const newEditForm = state.editForm;
      newEditForm.error = action.error;

      return { ...state, loading: false, editForm: newEditForm };
    }

    case UPDATE_USER_EDIT_FORM: {
      return { ...state, loading: false, editForm: action.payload, error: null };
    }

    case UPDATE_USER_EDIT_FORM_LOCATIONS: {
      return {
        ...state,
        editForm: {
          ...state.editForm,
          originalLocationUserIds: action.payload,
        },
      };
    }

    case RESET_TEMPORARY_PASSWORD_FAILURE: {
      return {
        ...state,
        editForm: {
          ...state.editForm,
          error: action.error,
          resetSubmitting: false,
        },
      };
    }
    case RESET_TEMPORARY_PASSWORD_REQUEST: {
      return {
        ...state,
        editForm: {
          ...state.editForm,
          error: null,
          resetSubmitting: true,
        },
      };
    }
    case RESET_TEMPORARY_PASSWORD_SUCCESS: {
      return {
        ...state,
        editForm: {
          ...state.editForm,
          error: null,
          resetSubmitting: false,
          successMessage: action.payload,
        },
      };
    }

    case RESET_USER_CREATE_FORM: {
      return { ...state, loading: false, createForm: initialState.createForm };
    }

    case UPDATE_USERS_SCOPEFILTER: {
      return {
        ...state,
        scopeFilter: {
          organizations: action.organizations,
          locations: action.locations,
          providers: action.providers,
        },
      };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default usersReducer;
