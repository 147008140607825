import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  GET_HRAS_FAILURE,
  GET_HRAS_REQUEST,
  GET_HRAS_SUCCESS,
  RESEND_REPORTS_FAILURE,
  RESEND_REPORTS_REQUEST,
  RESEND_REPORTS_SUCCESS,
  UPDATE_BILLING_SCOPEFILTER,
  UPDATE_BILLING_STATUS_FAILURE,
  UPDATE_BILLING_STATUS_REQUEST,
  UPDATE_BILLING_STATUS_SUCCESS,
} from '../types/billingTypes';

const schema = {
  type: 'object',
  properties: {
    data: { type: ['object', 'null'], optional: true },
    error: { type: ['string', 'null'], optional: true },
    billingStatusHasUpdated: { type: 'boolean', optional: true },
    isSubmitting: { type: 'boolean' },
    loading: { type: 'boolean' },
    resend: { type: ['object'] },
    scopeFilter: { type: ['object'], optional: true },
  },
};

const initialState = {
  data: null,
  error: null,
  billingStatusHasUpdated: false,
  isSubmitting: false,
  loading: false,
  resend: {
    error: null,
    isSubmitting: false,
  },
  scopeFilter: {},
};

const billingReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case GET_HRAS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_HRAS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_HRAS_SUCCESS: {
      return { ...state, hasUpdated: false, loading: false, data: action.payload, error: null };
    }

    case SET_INITIAL_STATE:
      return initialState;

    case UPDATE_BILLING_SCOPEFILTER: {
      return {
        ...state,
        scopeFilter: {
          organizations: action.organizations,
          locations: action.locations,
          providers: action.providers,
        },
      };
    }

    case RESEND_REPORTS_FAILURE: {
      return { ...state, resend: { ...state.resend, isSubmitting: false, error: action.error } };
    }
    case RESEND_REPORTS_REQUEST: {
      return { ...state, resend: { ...state.resend, error: null, isSubmitting: true } };
    }
    case RESEND_REPORTS_SUCCESS: {
      return { ...state, resend: { ...state.resend, error: null, isSubmitting: false } };
    }

    case UPDATE_BILLING_STATUS_FAILURE: {
      return { ...state, isSubmitting: false, error: action.error };
    }
    case UPDATE_BILLING_STATUS_REQUEST: {
      return { ...state, isSubmitting: true, error: null };
    }
    case UPDATE_BILLING_STATUS_SUCCESS: {
      return { ...state, error: null, hasUpdated: true, isSubmitting: false };
    }

    default:
      return state;
  }
};

export default billingReducer;
