import { replace, push } from 'connected-react-router';

const ROUTE = {
  LOGIN: {
    name: 'Login Page',
    path: '/login',
    route: () => replace('/login'),
  },
  AWV: {
    name: 'AWV Schedule',
    path: '/awv',
    route: () => replace('/awv'),
  },
  AWV_SCHEDULE: {
    name: 'AWV Schedule',
    path: '/awv/schedule',
    route: () => replace('/awv/schedule'),
  },
  AWV_LOCATIONS: {
    name: 'AWV Locations',
    path: '/awv/location-select',
    route: () => replace('/awv/location-select'),
  },
  AWV_CREATE_APPOINTMENT: {
    name: 'AWV Create Appointment',
    path: '/awv/create-appointment',
    route: () => replace('/awv/create-appointment'),
  },
  NEW_USER: {
    name: 'New User Page',
    path: '/newuser',
    route: () => replace('/newuser'),
  },
  FORGOT_PASSWORD: {
    name: 'Forgot Password Page',
    path: '/forgotpassword',
    route: () => replace('/forgotpassword'),
  },
  DASHBOARD: {
    name: 'Dashboard Page',
    path: '/',
    route: () => push('/'),
  },
  PATIENTS: {
    name: 'Patients Page',
    path: '/patients',
    route: () => replace('/patients'),
  },
  USERS: {
    name: 'Users Page',
    path: '/users',
    route: () => replace('/users'),
  },
  BILLING: {
    name: 'Billing Page',
    path: '/billing',
    route: () => replace('/billing'),
  },
  ORGANIZATIONS: {
    name: 'Organizations Page',
    path: '/organizations',
    route: () => replace('/organizations'),
  },
  LOCATIONS: {
    name: 'Locations Page',
    path: '/locations',
    route: () => replace('/locations'),
  },
  PROFILE: {
    name: 'Profile Page',
    path: '/profile',
    route: () => replace('/profile'),
  },
  PROVIDERS: {
    name: 'Providers Page',
    path: '/providers',
    route: () => replace('/providers'),
  },
};

export default ROUTE;
