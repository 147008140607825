export const AUTHENTICATE_PIN_FAILURE = 'AUTHENTICATE_PIN_FAILURE';
export const AUTHENTICATE_PIN_SUCCESS = 'AUTHENTICATE_PIN_SUCCESS';
export const AUTHENTICATE_PIN_REQUEST = 'AUTHENTICATE_PIN_REQUEST';
export const CLEAR_SELECTED_USER_REQUEST = 'CLEAR_SELECTED_USER_REQUEST';
export const FETCH_USERS_FOR_PIN_SELECT_FAILURE = 'FETCH_USERS_FOR_PIN_SELECT_FAILURE';
export const FETCH_USERS_FOR_PIN_SELECT_REQUEST = 'FETCH_USERS_FOR_PIN_SELECT_REQUEST';
export const FETCH_USERS_FOR_PIN_SELECT_SUCCESS = 'FETCH_USERS_FOR_PIN_SELECT_SUCCESS';
export const LOCKED_FOR_PIN = 'LOCKED_FOR_PIN';
export const SELECT_USER_FAILURE = 'SELECT_USER_FAILURE';
export const SELECT_USER_REQUEST = 'SELECT_USER_REQUEST';
export const SELECT_USER_SUCCESS = 'SELECT_USER_SUCCESS';
