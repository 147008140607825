/* eslint-disable */
export const getOrganizationWithLocations = /* GraphQL */ `
  query GetOrganization(
    $id: ID!
    $locationsNextToken: String
    $locationsLimit: Int
  ) {
    getOrganization(id: $id) {
      id
      locations(limit: $locationsLimit, nextToken: $locationsNextToken) {
        items {
          id
          name
          address {
            streetAddress
            city
            state
            zip
          }
        }
        nextToken
      }
    }
  }
`;
export const getLocationWithUsers = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      users(limit: 20000) {
        items {
          id
          user {
            id
            isActive
            firstName
            lastName
            awvRole
            emailAddress
            org {
              id
              name
              locations {
                items {
                  id
                  name
                }
              }
            }
            locations {
              items {
                id
                location {
                  id
                  name
                }
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const getOrganizationById = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      isActive
      name
      locations {
        items {
          id
          name
        }
      }
    }
  }
`;
export const getLocationById = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      providers {
        items {
          provider {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser(
    $id: ID!
    $locationsNextToken: String
    $locationsLimit: Int
  ) {
    getUser(id: $id) {
      awvRole
      emailAddress
      firstName
      id
      isActive
      lastName
      orgID
      pin
      locations(limit: $locationsLimit, nextToken: $locationsNextToken) {
        items {
          id
          location {
            id
            name
            address {
              streetAddress
              city
              state
              zip
            }
          }
        }
      }
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      telehealthURL
      hideRevWidget
      pinLength
      allowEligibilityHraBypass
      ssnEligibilityActive
    }
  }
`;
export const getUserWithOrg = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      awvRole
      emailAddress
      firstName
      id
      isActive
      lastName
      orgID
      org {
        id
        name
        locations {
          items {
            id
            name
          }
        }
      }
      locations {
        items {
          id
          location {
            id
            name
          }
        }
      }
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        address {
          city
          country
          county
          state
          streetAddress
          zip
        }
        description
        emailAddresses
        id
        isActive
        locationID
        name
        tin
        orgID
        phoneNumber {
          fax
          office
        }
        integrationType
      }
      nextToken
    }
  }
`;
export const listLocationsByOrg = /* GraphQL */ `
  query ListLocationsByOrg(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $orgID: ID!
  ) {
    listLocationsByOrg(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      orgID: $orgID
    ) {
      items {
        address {
          city
          country
          county
          state
          streetAddress
          zip
        }
        description
        id
        isActive
        locationID
        name
        tin
        orgID
        phoneNumber {
          fax
          office
        }
        integrationType
      }
      nextToken
    }
  }
`;
export const getLocationWithProviders = /* GraphQL */ `
  query GetLocation(
    $id: ID!
    $nextToken: String
  ) {
    getLocation(id: $id) {
      description
      emailAddresses
      id
      isActive
      locationID
      name
      tin
      orgID
      providers(limit: 1000, nextToken: $nextToken) {
        items {
          id
          locationID
          providerID
          provider {
            dob
            emailAddresses
            firstName
            id
            isActive
            lastName
            middleName
            npi
            orgID
            providerID
            sex
          }
        }
        nextToken
      }
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        addresses {
          city
          country
          county
          state
          streetAddress
          use
          zip
        }
        dob
        emailAddresses
        firstName
        id
        isActive
        lastName
        middleName
        npi
        orgID
        providerID
        sex
      }
      nextToken
    }
  }
`;
export const listProvidersByOrg = /* GraphQL */ `
  query ListProvidersByOrg(
    $orgID: ID!
    $limit: Int
    $nextToken: String
  ) {
    listProvidersByOrg(orgID: $orgID, limit: $limit, nextToken: $nextToken) {
      items {
        addresses {
          city
          country
          county
          state
          streetAddress
          use
          zip
        }
        dob
        emailAddresses
        firstName
        id
        isActive
        lastName
        middleName
        npi
        orgID
        providerID
        sex
      }
      nextToken
    }
  }
`;
export const listLocationProvidersByProvider = /* GraphQL */ `
  query ListLocationProvidersByProvider($providerID: ID!) {
    listLocationProvidersByProvider(providerID: $providerID) {
      items {
        location {
          id
          name
        }
        id
      }
      nextToken
    }
  }
`;
export const getProviderWithLocations = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      addresses {
        city
        country
        county
        state
        streetAddress
        use
        zip
      }
      dob
      emailAddresses
      firstName
      id
      isActive
      lastName
      locations {
        items {
          id
          location {
            id
            name
          }
        }
      }
      middleName
      npi
      org {
        id
        isActive
        name
        locations {
          items {
            id
            name
          }
        }
      }
      orgID
      providerID
      sex
    }
  }
`;
export const listPatientsByOrg = /* GraphQL */ `
  query ListPatientsByOrg(
    $orgID: ID
    $searchLastNameSearchFirstName: ModelPatientByOrganizationCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatientsByOrg(
      orgID: $orgID
      searchLastNameSearchFirstName: $searchLastNameSearchFirstName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        awvEligibleCode
        awvEligibleDate
        deathDateTime
        dob
        emailAddresses
        firstName
        hras {
          items {
            billingCode
            dateTime
            id
            isBilled
            isComplete
            locationID
            orgID
            patientID
            phqTotal
            providerID
            s3KeyPatientReport
            s3KeyProviderReport
            userID
            version
            vitals {
              bmi
              bpDiastolic
              bpSystolic
              height
              weight
            }
            vitalsOptOut
            location {
              integrationType
            }
            reportDeliveredPatient
            reportDeliveredProvider
          }
        }
        id
        isDeceased
        isHispanic
        lastName
        maritalStatus
        mbi
        middleName
        orgID
        patientID
        race
        sex
      }
      nextToken
    }
  }
`;
export const listPatientsForCount = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
  ) {
    listPatients(filter: $filter, limit: $limit) {
      items {
        id
      }
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      awvEligibleCode
      awvEligibleDate
      deathDateTime
      dob
      emailAddresses
      firstName
      hras {
        items {
          billingCode
          dateTime
          id
          isBilled
          isComplete
          locationID
          orgID
          patientID
          phqTotal
          providerID
          s3KeyPatientReport
          s3KeyProviderReport
          userID
          version
          vitals {
            bmi
            bpDiastolic
            bpSystolic
            height
            weight
          }
          vitalsOptOut
          location {
            integrationType
          }
        }
      }
      id
      isDeceased
      isHispanic
      lastName
      maritalStatus
      mbi
      middleName
      orgID
      patientID
      race
      sex
    }
  }
`;
export const listHras = /* GraphQL */ `
  query ListHras($filter: ModelHraFilterInput, $limit: Int, $nextToken: String) {
    listHras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        billingCode
        dateTime
        id
        isBilled
        isComplete
        locationID
        location {
          name
          address {
            zip
          }
          integrationType
        }
        orgID
        patient {
          dob
          firstName
          id
          lastName
          mbi
          orgID
          patientID
          sex
        }
        patientID
        phqTotal
        provider {
          id
          firstName
          lastName
          npi
        }
        providerID
        qas {
          aIds
          qId
          time
        }
        s3KeyPatientReport
        s3KeyProviderReport
        userID
        version
        vitals {
          bmi
          bpDiastolic
          bpSystolic
          height
          weight
        }
        vitalsOptOut
        surveyQas {
          surveyVersionID
          qas {
            aIds
            qId
            time
          }
        }
        reportDeliveredPatient
        reportDeliveredProvider
      }
      nextToken
    }
  }
`;
export const listHrasForRevenueMtd = /* GraphQL */ `
  query ListHras($filter: ModelHraFilterInput, $limit: Int, $nextToken: String) {
    listHras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        billingCode
        location {
          address {
            zip
          }
        }
      }
      nextToken
    }
  }
`;
export const listHrasByOrg = /* GraphQL */ `
  query ListHrasByOrg(
    $orgID: ID!
    $dateTime: ModelStringKeyConditionInput
    $filter: ModelHraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHrasByOrg(
      orgID: $orgID
      dateTime: $dateTime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        billingCode
        dateTime
        id
        isBilled
        isComplete
        locationID
        location {
          name
          address {
            zip
          }
          integrationType
        }
        orgID
        patient {
          dob
          firstName
          id
          lastName
          mbi
          orgID
          patientID
          sex
        }
        patientID
        phqTotal
        provider {
          id
          firstName
          lastName
          npi
        }
        providerID
        qas {
          aIds
          qId
          time
        }
        s3KeyPatientReport
        s3KeyProviderReport
        userID
        version
        vitals {
          bmi
          bpDiastolic
          bpSystolic
          height
          weight
        }
        vitalsOptOut
        surveyQas {
          surveyVersionID
          qas {
            aIds
            qId
            time
          }
        }
        reportDeliveredPatient
        reportDeliveredProvider
      }
      nextToken
    }
  }
`;
export const listHrasByOrgForRevenueMtd = /* GraphQL */ `
  query ListHrasByOrg(
    $orgID: ID!
    $dateTime: ModelStringKeyConditionInput
    $filter: ModelHraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHrasByOrg(
      orgID: $orgID
      dateTime: $dateTime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        billingCode
        location {
          address {
            zip
          }
        }
      }
      nextToken
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      createdAt
      currentSurveyVersion {
        id
        surveyID
        orgID
        createdAt
        langs
      }
      description
      disabled
      id
      name
      orgID
      org {
        customSurvey {
          id
        }
        id
        name
        locations {
          items {
            id
            name
            customSurvey {
              id
            }
          }
        }
        providers {
          items {
            firstName
            id
            isActive
            lastName
            providerID
            customSurvey {
              id
            }
          }
        }
      }
      surveyVersions {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listSurveysWithOrgs = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        description
        disabled
        id
        name
        orgID
        org {
          customSurvey {
            id
          }
          id
          name
          locations {
            items {
              id
              name
              customSurvey {
                id
              }
            }
          }
          providers {
            items {
              firstName
              id
              isActive
              lastName
              providerID
              customSurvey {
                id
              }
            }
          }
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSurveyVersions = /* GraphQL */ `
  query ListSurveyVersions(
    $filter: ModelSurveyVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyID
      }
      nextToken
    }
  }
`;
export const getOrganizationStats = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      totalPatientCount
      annualExpectedCompletions
    }
  }
`;
export const listOrganizationsWithStats = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        totalPatientCount
        annualExpectedCompletions
      }
      nextToken
    }
  }
`;
export const listFederatedDomains = /* GraphQL */ `
  query ListFederatedDomains {
    listFederatedDomains
  }
`;
export const listOrgAdminsByOrg = /* GraphQL */ `
  query ListUsersByOrg(
    $orgID: ID!
    $filter: ModelUserFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listUsersByOrg(
      orgID: $orgID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        emailAddress
        firstName
        lastName
        awvRole
        pin
        id
        isActive
        org {
          pinLength
        }
        orgID
      }
      nextToken
    }
  }
`;
export const listUsersByOrg = /* GraphQL */ `
  query ListUsersByOrg(
    $orgID: ID!
    $filter: ModelUserFilterInput
    $nextToken: String
    $limit: Int
  ) {
    listUsersByOrg(
      orgID: $orgID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
          id
          isActive
          firstName
          lastName
          awvRole
          emailAddress
          org {
            id
            name
          }
        }
      nextToken
    }
  }
`;
export const getUserWithLocations = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      isActive
      firstName
      lastName
      awvRole
      emailAddress
      org {
        id
        name
      }
      locations {
        items {
          id
          location {
            id
            name
          }
        }
      }
    }
  }
`;
export const getLocationWithUsersForPinSelect = /* GraphQL */ `
  query GetLocation($id: ID!, $nextToken: String, $limit: Int) {
    getLocation(id: $id) {
      id
      users(limit: $limit, nextToken: $nextToken) {
        items {
          id
          user {
            emailAddress
            firstName
            lastName
            awvRole
            pin
            id
            isActive
            org {
              pinLength
            }
            orgID
          }
        }
        nextToken
      }
    }
  }
`;
export const authenticatePin =  /* GraphQL */ `
  query AuthenticatePin($id: ID!, $pin: Int!) {
    authenticatePin(id: $id, pin: $pin)
  }
`;
export const getLocationAppointments = /* GraphQL */ `
  query GetLocationAppointments(
    $id: ID!,
    $startDate: AWSDateTime!,
    $endDate: AWSDateTime!
  ) {
    getLocation(id: $id) {
      id
      name
      appointments(
        limit: 1000
        dateTimePatientID: {
        between: [
            {dateTime: $startDate},
            {dateTime: $endDate}
          ]
        }
      ) {
        items {
          id
          dateTime
          billingCode
          locationID
          patient {
            firstName
            lastName
            dob
            mbi
            id
            sex
            patientID
            awvEligibleDate
            orgID
          }
          attendingProvider {
            firstName
            lastName
            providerID
            id
          }
          awvEligible
          hra {
            id
            s3KeyPatientReport
            s3KeyProviderReport
            patient {
              firstName
              lastName
            }
            isComplete
            vitals {
              height
              weight
              bpDiastolic
              bpSystolic
            }
            vitalsOptOut
          }
        }
      }
    }
  }
`;
export const getAppointment = /* GraphQL */ `
  query GetAppointment($id: ID!) {
    getAppointment(id: $id) {
      id
      dateTime
      billingCode
      locationID
      patient {
        firstName
        lastName
        dob
        mbi
        id
        sex
        patientID
        awvEligibleDate
        orgID
      }
      attendingProvider {
        firstName
        lastName
        providerID
        id
      }
      awvEligible
      hra {
        id
        s3KeyPatientReport
        s3KeyProviderReport
        patient {
          firstName
          lastName
        }
        isComplete
        vitals {
          height
          weight
          bpDiastolic
          bpSystolic
        }
        vitalsOptOut
      }
    }
  }
`;
export const updateEligibility = /* GraphQL */ `
  query Eligibility($id: ID!, $ssn: String) {
    eligibility(appointmentId: $id, ssn: $ssn) {
      awvEligible
      billingCode
      id
      patient {
        mbi
      }
    }
  }
`;
export const getCascadedSurveyForAppointment = /* GraphQL */ `
  query GetCascadedSurveyForAppointment(
    $appointmentId: ID!
    $langCode: String
  ) {
    getCascadedSurveyForAppointment(
      appointmentId: $appointmentId
      langCode: $langCode
    )
  }
`;

export const getHraReports = /* GraphQL */ `
  query GetHra($id: ID!) {
    getHra(id: $id) {
      id
      s3KeyPatientReport
      s3KeyProviderReport
      reportDeliveredPatient
      reportDeliveredProvider
      appointment {
        id
      }
    }
  }
`;

export const getHraReportUrl = /* GraphQL */ `
  query GetHraReportUrl($reportS3Path: String!) {
    getHraReportUrl(reportS3Path: $reportS3Path)
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address {
          streetAddress
          city
          state
          zip
        }
        name
        isActive
        ssnEligibilityActive
        telehealthURL
        hideRevWidget
        cognitoAppClientID
        cognitoIdpType
        ssoDomains
        totalPatientCount
        pinLength
        annualExpectedCompletions
        createdAt
        updatedAt
        allowEligibilityHraBypass
      }
      nextToken
    }
  }
`;
export const getCustomSurveyQuestions = /* GraphQL */ `
  query GetCustomSurveyQuestions($surveyId: ID!, $langCode: String) {
    getCustomSurveyQuestions(surveyId: $surveyId, langCode: $langCode)
  }
`;
