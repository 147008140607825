import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { googleAnalytics } from './reactGaMiddleware';
import createRootReducer from './reducers';

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {
  const middlewares = [ReduxThunk, routerMiddleware(history)];
  if (process.env.REACT_APP_FORCE_ANALYTICS === 'true' || process.env.NODE_ENV === 'production') {
    middlewares.push(googleAnalytics);
  }

  const enhancers = [applyMiddleware(...middlewares)];
  const composeEnhancers = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false, trace: process.env.NODE_ENV !== 'production', traceLimit: 30 })
    : compose;

  return createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(...enhancers),
  );
}
