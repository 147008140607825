import React from 'react';

export const riskCategories = [
  {
    index: 1,
    name: 'Cardiovascular Disease',
    triggers: {
      primary: (
        <ul>
          <li>Consider patient education to include consuming a healthy plant-based or Mediterranean-like diet high in vegetables, fruits, nuts, whole grains, lean vegetable or animal protein (preferably fish), and vegetable fiber.</li>
          <li>Consider patient education to included engaging in at least 150 minutes/week of moderate-intensity or 75 minutes/week of vigorous-intensity physical activity including resistance exercise.</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>
            Consider patient education to include consuming a healthy plant-based or Mediterranean-like diet high
            in vegetables, fruits, nuts, whole grains, lean vegetable or animal protein (preferably fish), and
            vegetable fiber
          </li>
          <li>
            Consider patient education to included engaging in at least 150 minutes/week of moderate-intensity or
            75 minutes/week of vigorous-intensity physical activity including resistance exercise.
          </li>
          <li>Consider maintaining blood pressure below 140/85 mm Hg (130/80 mm Hg in patients with diabetes)</li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider medications to limit exacerbation of cardiovascular condition as appropriate</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider patient education to include consuming a healthy plant-based or Mediterranean-like diet high
            in vegetables, fruits, nuts, whole grains, lean vegetable or animal protein (preferably fish), and
            vegetable fiber
          </li>
          <li>Consider patient education to included engaging in at least 150 minutes/week of moderate-intensity or
            75 minutes/week of vigorous-intensity physical activity including resistance exercise.
          </li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider medications to limit exacerbation of cardiovascular condition as appropriate</li>
          <li>Consider additional medications to maintain blood pressure less than 140/85)</li>
        </ul>
      ),
    },
  },
  {
    index: 2,
    name: 'Diabetes',
    triggers: {
      primary: (
        <ul>
          <li>Consider patient education to include consuming a healthy plant-based or Mediterranean-like diet high
            in vegetables, fruits, nuts, whole grains, lean vegetable or animal protein (preferably fish), and
            vegetable fiber
          </li>
          <li>Consider patient education to included engaging in at least 150 minutes/week of moderate-intensity or
            75 minutes/week of vigorous-intensity physical activity including resistance exercise.
          </li>
          <li>Consider smoking cessation counseling as appropriate</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider patient education to include consuming a healthy plant-based or Mediterranean-like diet high
            in vegetables, fruits, nuts, whole grains, lean vegetable or animal protein (preferably fish), and
            vegetable fiber
          </li>
          <li>Consider patient education to included engaging in at least 150 minutes/week of moderate-intensity or
            75 minutes/week of vigorous-intensity physical activity including resistance exercise.
          </li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider frequent monitoring and maintaining a controlled Hemoglobin A1C level</li>
          <li>Consider medications to limit complications of Diabetes as appropriate</li>
        </ul>
      ),
    },
  },
  {
    index: 3,
    name: 'COPD/Emphysema',
    triggers: {
      primary: (
        <ul>
          <li>Consider use of COPD risk assessment tools in primary care to identify those most likely to develop
            clinically significant COPD
          </li>
          <li>Consider screening for tobacco use and occupational hazards, history of recurrent pneumonia's and lower
            respiratory tract infections
          </li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider patient education on regular exercise and active lifestyle</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider Pulmonary Rehab as appropriate</li>
          <li>Consider patient education on regular exercise and active lifestyle</li>
          <li>Consider annual Influenza Vaccinations to prevent exacerbation of COPD</li>
          <li>Consider patient education on avoidance of environmental triggers to avoid exacerbation of COPD</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider Pulmonary Rehab as appropriate</li>
          <li>Consider patient education on regular exercise and active lifestyle</li>
          <li>Consider annual Influenza Vaccinations to prevent exacerbation of COPD</li>
          <li>Consider patient education on avoidance of environmental triggers to avoid exacerbation of COPD</li>
        </ul>
      ),
    },
  },
  {
    index: 4,
    name: 'Stroke',
    triggers: {
      primary: (
        <ul>
          <li>Consider patient education on adequate blood pressure control</li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider preventive medication management for high risk patients</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider patient education on adequate blood pressure control</li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider medication management for high risk patients</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider patient education on adequate blood pressure control</li>
          <li>Consider smoking cessation counseling as appropriate</li>
          <li>Consider medication management for high risk patients</li>
          <li>Consider rehabilitation to reestablish partial or complete independence and quality of life when
            applicable
          </li>
        </ul>
      ),
    },
  },
  {
    index: 5,
    name: 'Tobacco',
    triggers: {
      primary: (
        <ul>
          <li>Consider annual tobacco use screening</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider smoking cessation counseling as appropriate</li>
        </ul>
      ),
    },
  },
  {
    index: 6,
    name: 'Arthritis/Osteoarthritis',
    triggers: {
      primary: (
        <ul>
          <li>Consider patient education to maintain BMI between 18.5 and 24.9</li>
          <li>Consider patient education to engage in regular physical activity</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider patient education to maintain BMI between 18.5 and 24.9</li>
          <li>Consider patient education to engage in regular physical activity</li>
          <li>Consider chondroprotective strategies to prevent further disease progression as appropriate</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider patient education to maintain BMI between 18.5 and 24.9</li>
          <li>Consider patient education to engage in regular physical activity</li>
          <li>Consider chondroprotective strategies to prevent further disease progression as appropriate</li>
          <li>Consider Falls Risk Assessment given history of previous fall and diagnosis of osteoarthritis as
            appropriate
          </li>
        </ul>
      ),
    },
  },
  {
    index: 7,
    name: 'Gait Instability / Fall',
    triggers: {
      primary: (
        <ul>
          <li>Consider patient education to maintain BMI between 18.5 and 24.9</li>
          <li>Consider patient education to engage in regular physical activity</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider patient education to engage in regular physical activity</li>
          <li>Consider referring patient for a home safety evaluation if indicated</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider patient education to maintain BMI between 18.5 and 24.9</li>
          <li>Consider patient education to engage in regular physical activity</li>
          <li>Consider referring patient for a home safety evaluation if indicated</li>
          <li>Consider performing a Falls Risk Assessment and Falls Screening as appropriate</li>
        </ul>
      ),
    },
  },
  {
    index: 8,
    name: 'Depression',
    triggers: {
      primary: (
        <ul>
          <li>Consider regular screening for Depression as appropriate</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider regular assessment of Depression utilizing a PHQ9 as indicated</li>
          <li>Consider referral for psychological evaluation and treatment as indicated</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider referral for psychological evaluation and treatment as indicated</li>
        </ul>
      ),
    },
  },
  {
    index: 9,
    name: 'Substance Abuse',
    triggers: {
      primary: (
        <ul>
          <li>Consider regular screenign for Substance Abuse as appropriate</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider referral for substance abuse counseling as indicated</li>
        </ul>
      ),
    },
  },
  {
    index: 10,
    name: 'Cognitive Impairment',
    triggers: {
      primary: (
        <ul>
          <li>Consider regular screening for cognitive impairment as appropriate</li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider referral for further cognitive evaluation and treatment as indicated</li>
        </ul>
      ),
    },
  },
  {
    index: 11,
    name: 'Hypertension',
    triggers: {
      primary: (
        <ul>
          <li>Consider patient education to maintain normal body weight for adults (body mass index 18.5–24.9
            kg/m2)
          </li>
          <li>Consider patient education to reduce dietary sodium intake to no more than 100 mmol per day
            (approximately 2.4 g of sodium or 6 g of sodium chloride).
          </li>
          <li>Consider patient education to engage in regular aerobic physical activity such as brisk walking (at
            least 30 minutes per day, most days of the week).
          </li>
          <li>Consider patient education to limit alcohol consumption to no more than 1 oz (30 mL) ethanol (e.g., 24
            oz [720 mL] of beer, 10 oz [300 mL] of wine, or 2 oz [60 mL] 100-proof whiskey) per day in most men and
            to no more than 0.5 oz (15 mL) of ethanol per day in women and lighter weight persons.
          </li>
        </ul>
      ),
      secondary: (
        <ul>
          <li>Consider patient education to maintain normal body weight for adults (body mass index 18.5–24.9
            kg/m2)
          </li>
          <li>Consider patient education to reduce dietary sodium intake to no more than 100 mmol per day
            (approximately 2.4 g of sodium or 6 g of sodium chloride).
          </li>
          <li>Consider patient education to engage in regular aerobic physical activity such as brisk walking (at
            least 30 minutes per day, most days of the week).
          </li>
          <li>Consider patient education to limit alcohol consumption to no more than 1 oz (30 mL) ethanol (e.g., 24
            oz [720 mL] of beer, 10 oz [300 mL] of wine, or 2 oz [60 mL] 100-proof whiskey) per day in most men and
            to no more than 0.5 oz (15 mL) of ethanol per day in women and lighter weight persons.
          </li>
          <li>Consider medication management to control blood pressure as appropriate</li>
        </ul>
      ),
      tertiary: (
        <ul>
          <li>Consider patient education to maintain normal body weight for adults (body mass index 18.5–24.9
            kg/m2)
          </li>
          <li>Consider patient education to reduce dietary sodium intake to no more than 100 mmol per day
            (approximately 2.4 g of sodium or 6 g of sodium chloride).
          </li>
          <li>Consider patient education to engage in regular aerobic physical activity such as brisk walking (at
            least 30 minutes per day, most days of the week).
          </li>
          <li>Consider patient education to limit alcohol consumption to no more than 1 oz (30 mL) ethanol (e.g., 24
            oz [720 mL] of beer, 10 oz [300 mL] of wine, or 2 oz [60 mL] 100-proof whiskey) per day in most men and
            to no more than 0.5 oz (15 mL) of ethanol per day in women and lighter weight persons.
          </li>
          <li>Consider additional medication to control blood pressure</li>
        </ul>
      ),
    },
  },
];
