import { cloneDeep } from 'lodash';

import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CHECK_FOR_REPORTS_FAILURE,
  CHECK_FOR_REPORTS_REQUEST,
  CHECK_FOR_REPORTS_SUCCESS,
  CREATE_APPOINTMENT_FAILURE,
  CREATE_APPOINTMENT_REQUEST,
  CREATE_APPOINTMENT_SUCCESS,
  CLOSE_REPORTS_MODAL,
  EMAIL_REPORT_FAILURE,
  EMAIL_REPORT_REQUEST,
  EMAIL_REPORT_SUCCESS,
  GET_APPOINTMENTS_FOR_LOCATION_FAILURE,
  GET_APPOINTMENTS_FOR_LOCATION_REQUEST,
  GET_APPOINTMENTS_FOR_LOCATION_SUCCESS,
  GET_AWV_APPOINTMENT_FAILURE,
  GET_AWV_APPOINTMENT_REQUEST,
  GET_AWV_APPOINTMENT_SUCCESS,
  GET_LOCATIONS_FOR_AWV_FAILURE,
  GET_LOCATIONS_FOR_AWV_REQUEST,
  GET_LOCATIONS_FOR_AWV_SUCCESS,
  GET_REPORT_PRESIGNED_URL_FAILURE,
  GET_REPORT_PRESIGNED_URL_REQUEST,
  GET_REPORT_PRESIGNED_URL_SUCCESS,
  OPEN_REPORTS_MODAL,
  REMOVE_APPOINTMENTS_FOR_AWV_REQUEST,
  RESET_AWV_APPOINTMENT_MODAL,
  SELECT_APPOINTMENT_FOR_AWV,
  SELECT_LOCATION_FOR_AWV_FAILURE,
  SELECT_LOCATION_FOR_AWV_REQUEST,
  SELECT_LOCATION_FOR_AWV_SUCCESS,
  UPDATE_APPOINTMENT_AND_HRA_VITALS_SUCCESS,
  UPDATE_ELIGIBILITY_FAILURE,
  UPDATE_ELIGIBILITY_REQUEST,
  UPDATE_ELIGIBILITY_SUCCESS,
  UPDATE_HRA_VITALS_FAILURE,
  UPDATE_HRA_VITALS_REQUEST,
  UPDATE_HRA_VITALS_SUCCESS,
  UPDATE_PATIENT_AND_APPOINTMENT_FAILURE,
  UPDATE_PATIENT_AND_APPOINTMENT_REQUEST,
  UPDATE_PATIENT_AND_APPOINTMENT_SUCCESS,
} from '../types/awvTypes';

const schema = {
  type: 'object',
  properties: {
    error: { type: ['string', 'null'], optional: true },
    locations: { type: ['object'] },
    selectedLocationID: { type: ['string', 'null'] },
    appointments: {
      type: ['object'],
      properties: {
        data: { type: ['object'] },
        loading: { type: 'boolean' },
        create: {
          type: ['object'],
          properties: {
            error: { type: ['string', 'null'], optional: true },
          },
        },
        edit: {
          type: ['object'],
          properties: {
            error: { type: ['string', 'null'], optional: true },
            loading: { type: 'boolean' },
          },
        },
        reportChecking: { type: 'boolean' },
        selectedId: { type: ['string', 'null'] },
      },
    },
    reports: { type: 'object' },
  },
};

const initialState = {
  error: null,
  locations: {},
  selectedLocationID: null,
  appointments: {
    data: {},
    loading: false,
    create: {
      error: null,
    },
    edit: {
      error: null,
      loading: false,
    },
    reportChecking: false,
    selectedId: null,
  },
  reports: {
    loading: false,
    error: null,
    modalIsOpen: false,
    reportUrl: null,
  },
};

const awvReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CHECK_FOR_REPORTS_REQUEST:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          reportChecking: true,
          edit: {
            ...state.appointments.edit,
            error: null,
          },
        },
      };
    case CHECK_FOR_REPORTS_SUCCESS: {
      const newAppointments = state.appointments.data;
      let { hra } = newAppointments[action.payload.appointment.id];
      hra = { ...hra, ...action.payload.hra };
      newAppointments[action.payload.appointment.id].hra = hra;

      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: newAppointments,
          reportChecking: false,
          edit: {
            ...state.appointments.edit,
            error: null,
          },
        },
      };
    }
    case CHECK_FOR_REPORTS_FAILURE:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          reportChecking: false,
          edit: {
            ...state.appointments.edit,
            error: action.error,
          },
        },
      };

    case CREATE_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          create: { ...state.appointments.create, error: action.error },
        },
      };
    case CREATE_APPOINTMENT_REQUEST:
      return {
          ...state,
          appointments: {
            ...state.appointments,
            create: { ...state.appointments.create, error: null },
          },
        };
    case CREATE_APPOINTMENT_SUCCESS: {
      const newAppointments = state.appointments.data;
      newAppointments[action.payload.id] = action.payload;

      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: newAppointments,
          create: {
            ...state.appointments.create,
            error: null,
          },
        },
      };
    }

    case EMAIL_REPORT_FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          error: action.error,
        },
      };
    case EMAIL_REPORT_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          error: null,
        },
      };
    case EMAIL_REPORT_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          error: null,
        },
      };

    case GET_LOCATIONS_FOR_AWV_FAILURE:
      return { ...state, loading: false, error: action.error };
    case GET_LOCATIONS_FOR_AWV_REQUEST:
        return { ...state, loading: true, error: null };
    case GET_LOCATIONS_FOR_AWV_SUCCESS:
      return { ...state, loading: false, error: null, locations: action.payload };

    case SELECT_APPOINTMENT_FOR_AWV:
      return { ...state, appointments: { ...state.appointments, selectedId: action.id } };

    case SELECT_LOCATION_FOR_AWV_FAILURE:
      return { ...state, loading: false, error: action.error };
    case SELECT_LOCATION_FOR_AWV_REQUEST:
        return { ...state, loading: true, error: null };
    case SELECT_LOCATION_FOR_AWV_SUCCESS:
      return { ...state, loading: false, selectedLocationID: action.payload };

    case GET_AWV_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: {
            ...state.appointments.edit,
            error: action.error,
            loading: false,
          },
        },
      };
    case GET_AWV_APPOINTMENT_REQUEST:
      return {
          ...state,
          appointments: {
            ...state.appointments,
            edit: {
              ...state.appointments.edit,
              error: null,
              loading: true,
            },
          },
        };
    case GET_AWV_APPOINTMENT_SUCCESS: {
      const newAppointments = state.appointments.data;
      newAppointments[action.payload.id] = action.payload;

      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: newAppointments,
          edit: {
            ...state.appointments.edit,
            error: null,
            loading: false,
          },
        },
      };
    }

    case GET_APPOINTMENTS_FOR_LOCATION_FAILURE:
      return { ...state, appointments: { ...state.appointments, error: action.error, loading: false } };
    case GET_APPOINTMENTS_FOR_LOCATION_REQUEST:
        return { ...state, appointments: { ...state.appointments, error: null, loading: true } };
    case GET_APPOINTMENTS_FOR_LOCATION_SUCCESS:
      return { ...state, appointments: { ...state.appointments, error: null, loading: false, data: action.payload || {} } };

    case GET_REPORT_PRESIGNED_URL_FAILURE:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: action.error,
        },
      };
    case GET_REPORT_PRESIGNED_URL_REQUEST:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: true,
          error: null,
        },
      };
    case GET_REPORT_PRESIGNED_URL_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          loading: false,
          error: null,
          reportUrl: action.payload,
        },
      };

    case REMOVE_APPOINTMENTS_FOR_AWV_REQUEST:
      return { ...state, appointments: { ...state.appointments, data: {} } };

    case UPDATE_PATIENT_AND_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: action.error },
        },
      };
    case UPDATE_PATIENT_AND_APPOINTMENT_REQUEST:
      return {
          ...state,
          appointments: {
            ...state.appointments,
            edit: { ...state.appointments.edit, error: null },
          },
        };
    case UPDATE_PATIENT_AND_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: null },
        },
      };

    case UPDATE_ELIGIBILITY_FAILURE:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: action.error },
        },
      };
    case UPDATE_ELIGIBILITY_REQUEST:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: null },
        },
      };
    case UPDATE_ELIGIBILITY_SUCCESS: {
      const { id, billingCode, awvEligible } = action.payload;
      const newAppointments = state.appointments.data;
      newAppointments[id] = {
        ...newAppointments[id],
        billingCode,
        awvEligible,
      };

      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: newAppointments,
          edit: {
            ...state.appointments.edit,
            error: null,
            loading: false,
          },
        },
      };
    }

    case UPDATE_HRA_VITALS_FAILURE:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: action.error },
        },
      };
    case UPDATE_HRA_VITALS_REQUEST:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: null },
        },
      };
    case UPDATE_HRA_VITALS_SUCCESS: {
      const newAppointments = state.appointments.data;
      newAppointments[action.appointmentID] = {
        ...newAppointments[action.appointmentID],
        hra: action.payload,
      };
      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: newAppointments,
          edit: { ...state.appointments.edit, error: null },
        },
      };
    }
    case UPDATE_APPOINTMENT_AND_HRA_VITALS_SUCCESS: {
      const newAppointments = state.appointments.data;
      newAppointments[action.appointmentID] = action.payload;
      return {
        ...state,
        appointments: {
          ...state.appointments,
          data: newAppointments,
          edit: { ...state.appointments.edit, error: null },
        },
      };
    }

    case CLOSE_REPORTS_MODAL:
      return {
        ...state,
        reports: {
          ...state.reports,
          modalIsOpen: false,
          reportUrl: null,
        },
      };
    case OPEN_REPORTS_MODAL:
      return {
        ...state,
        reports: {
          ...state.reports,
          modalIsOpen: true,
          reportUrl: action.reportUrl,
        },
      };

    case RESET_AWV_APPOINTMENT_MODAL:
      return {
        ...state,
        appointments: {
          ...state.appointments,
          edit: { ...state.appointments.edit, error: null },
        },
      };

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default awvReducer;
