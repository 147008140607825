import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import {
  CALC_REVENUEMTD_FAILURE,
  CALC_REVENUEMTD_REQUEST,
  CALC_REVENUEMTD_SUCCESS,
  FETCH_MTD_HRAS_FAILURE,
  FETCH_MTD_HRAS_REQUEST,
  FETCH_MTD_HRAS_SUCCESS,
  FETCH_PATIENT_COUNT_FAILURE,
  FETCH_PATIENT_COUNT_REQUEST,
  FETCH_PATIENT_COUNT_SUCCESS,
  UPDATE_DASHBOARD_SCOPEFILTER,
} from '../types/dashboardTypes';
import { SET_INITIAL_STATE } from '../types/genericTypes';

const schema = {
  type: 'object',
  properties: {
    scopeFilter: { type: ['object'], optional: true },
    monthExpectedWidget: {
      type: ['object'],
      optional: true,
      properties: {
        data: { type: ['object', 'null'], optional: true },
        error: { type: ['string', 'null'], optional: true },
        loading: { type: 'boolean' },
      },
    },
    mtdAwvsWidget: {
      type: ['object'],
      optional: true,
      properties: {
        data: { type: ['object', 'null'], optional: true },
        error: { type: ['string', 'null'], optional: true },
        loading: { type: 'boolean' },
      },
    },
    mtdRevenueWidget: {
      type: ['object'],
      optional: true,
      properties: {
        data: { type: 'object' },
        error: { type: ['string', 'null'], optional: true },
        loading: { type: 'boolean' },
      },
    },
  },
};

const initialState = {
  scopeFilter: {},
  monthExpectedWidget: {
    data: {
      annualExpectedCompletions: 0,
      patientCount: 0,
    },
    error: null,
    loading: false,
  },
  mtdAwvsWidget: { data: null, error: null, loading: false },
  mtdRevenueWidget: { data: { revenueAmount: 0.00 }, error: null, loading: false },
};

const dashboardReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CALC_REVENUEMTD_FAILURE: {
      return {
        ...state,
        mtdRevenueWidget: {
          ...state.mtdRevenueWidget,
          error: action.error,
          loading: false,
        },
      };
    }
    case CALC_REVENUEMTD_REQUEST: {
      return {
        ...state,
        mtdRevenueWidget: {
          ...state.mtdRevenueWidget,
          loading: true,
        },
      };
    }
    case CALC_REVENUEMTD_SUCCESS: {
      return {
        ...state,
        mtdRevenueWidget: {
          data: { revenueAmount: action.payload },
          error: null,
          loading: false,
        },
      };
    }

    case FETCH_MTD_HRAS_FAILURE: {
      return {
        ...state,
        mtdAwvsWidget: {
          ...state.mtdAwvsWidget,
          error: action.error,
          loading: false,
        },
      };
    }
    case FETCH_MTD_HRAS_REQUEST: {
      return {
        ...state,
        mtdAwvsWidget: {
          ...state.mtdAwvsWidget,
          loading: true,
        },
      };
    }
    case FETCH_MTD_HRAS_SUCCESS: {
      return {
        ...state,
        mtdAwvsWidget: {
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    }

    case FETCH_PATIENT_COUNT_FAILURE: {
      return {
        ...state,
        monthExpectedWidget: {
          ...state.monthExpectedWidget,
          error: action.error,
          loading: false,
        },
      };
    }
    case FETCH_PATIENT_COUNT_REQUEST: {
      return {
        ...state,
        monthExpectedWidget: {
          ...state.monthExpectedWidget,
          loading: true,
        },
      };
    }
    case FETCH_PATIENT_COUNT_SUCCESS: {
      return {
        ...state,
        monthExpectedWidget: {
          data: {
            annualExpectedCompletions: action.payload[0],
            patientCount: action.payload[1],
          },
          error: null,
          loading: false,
        },
      };
    }

    case UPDATE_DASHBOARD_SCOPEFILTER: {
      return {
        ...state,
        scopeFilter: {
          organizations: action.organizations,
          locations: action.locations,
          providers: action.providers,
        },
      };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default dashboardReducer;
