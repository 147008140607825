/* eslint-disable */
export const updateProfile = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      id
      isActive
      firstName
      lastName
      awvRole
      emailAddress
      locations {
        items {
          location {
            id
            name
          }
        }
      }
    }
  }`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
  ) {
    updateUser(input: $input) {
      id
      isActive
      firstName
      lastName
      awvRole
      emailAddress
      org {
        id
        name
        locations {
          items {
            id
            name
          }
        }
      }
      locations {
        items {
          location {
            id
            name
          }
        }
      }
    }
  }`;

export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $condition: ModelLocationConditionInput
    $input: CreateLocationInput!
  ) {
    createLocation(condition: $condition, input: $input) {
      address {
        city
        country
        county
        state
        streetAddress
        zip
      }
      description
      emailAddresses
      id
      isActive
      locationID
      name
      tin
      orgID
      phoneNumber {
        fax
        office
      }
      integrationType
    }
  }`;

export const createLocationAndReturnWithOrg = /* GraphQL */ `
  mutation CreateLocation(
    $condition: ModelLocationConditionInput
    $input: CreateLocationInput!
  ) {
    createLocation(condition: $condition, input: $input) {
      address {
        city
        country
        county
        state
        streetAddress
        zip
      }
      description
      emailAddresses
      id
      isActive
      locationID
      name
      tin
      org {
        id
        isActive
        name
      }
      orgID
      phoneNumber {
        fax
        office
      }
      integrationType
    }
  }`;

export const createLocationUser = /* GraphQL */ `
  mutation CreateLocationUser(
    $input: CreateLocationUserInput!
  ) {
    createLocationUser(input: $input) {
      id
      location {
        id
        name
      }
    }
  }`;

export const createNonFederatedUser = /* GraphQL */ `
  mutation CreateNonFederatedUser($input: CreateNonFederatedUserInput!) {
    createNonFederatedUser(input: $input) {
      awvRole
      emailAddress
      firstName
      id
      isActive
      lastName
      org {
        id
        isActive
        name
        locations {
          items {
            id
            name
          }
        }
      }
      orgID
    }
  }`;

export const deleteLocationUser = /* GraphQL */ `
  mutation DeleteLocationUser(
    $input: DeleteLocationUserInput!
  ) {
    deleteLocationUser(input: $input) {
      id
    }
  }`;

export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $condition: ModelLocationConditionInput
    $input: UpdateLocationInput!
  ) {
    updateLocation(condition: $condition, input: $input) {
      address {
        city
        country
        county
        state
        streetAddress
        zip
      }
      description
      emailAddresses
      id
      isActive
      locationID
      name
      tin
      orgID
      phoneNumber {
        fax
        office
      }
      integrationType
    }
  }`;

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $condition: ModelUserConditionInput
    $input: CreateUserInput!
  ) {
    createUser(condition: $condition, input: $input) {
      awvRole
      emailAddress
      firstName
      id
      isActive
      lastName
      locations {
        nextToken
      }
      orgID
    }
  }`;

export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $condition: ModelOrganizationConditionInput
    $input: UpdateOrganizationInput!
  ) {
    updateOrganization(condition: $condition, input: $input) {
      address {
        city
        country
        county
        state
        streetAddress
        zip
      }
      id
      isActive
      ssnEligibilityActive
      locations {
        items {
          id
          name
          isActive
        }
      }
      name
      telehealthURL
      hideRevWidget
    }
  }`;

export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider(
    $condition: ModelProviderConditionInput
    $input: UpdateProviderInput!
  ) {
    updateProvider(condition: $condition, input: $input) {
      addresses {
        city
        country
        county
        state
        streetAddress
        use
        zip
      }
      dob
      emailAddresses
      firstName
      id
      isActive
      lastName
      locations {
        items {
          id
          location {
            id
            name
          }
        }
      }
      middleName
      npi
      org {
        id
        isActive
        name
        locations {
          items {
            id
            name
          }
        }
      }
      orgID
      phoneNumber {
        home
        mobile
        office
      }
      providerID
      sex
    }
  }`;

export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      orgID
      patientID
      mbi
      firstName
      lastName
      middleName
      searchFirstName
      searchLastName
      dob
      sex
      race
      emailAddresses
      phoneNumber {
        home
        mobile
        work
      }
      address {
        streetAddress
        city
        state
        zip
        county
        country
      }
      maritalStatus
      awvEligibleDate
      awvEligibleCode
      createdAt
      updatedAt
      org {
        id
      }
      hras {
        nextToken
      }
    }
  }
`;

export const createProvider = /* GraphQL */ `
  mutation CreateProvider(
    $condition: ModelProviderConditionInput
    $input: CreateProviderInput!
  ) {
    createProvider(condition: $condition, input: $input) {
      addresses {
        city
        country
        county
        state
        streetAddress
        use
        zip
      }
      dob
      emailAddresses
      firstName
      id
      isActive
      lastName
      middleName
      npi
      orgID
      phoneNumber {
        home
        mobile
        office
      }
      providerID
      sex
    }
  }`;

export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $condition: ModelPatientConditionInput
    $input: UpdatePatientInput!
  ) {
    updatePatient(condition: $condition, input: $input) {
      awvEligibleCode
      awvEligibleDate
      deathDateTime
      dob
      emailAddresses
      firstName
      hras {
        items {
          billingCode
          dateTime
          id
          isBilled
          isComplete
          locationID
          orgID
          patientID
          phqTotal
          providerID
          s3KeyPatientReport
          s3KeyProviderReport
          userID
          version
          vitals {
            bmi
            bpDiastolic
            bpSystolic
            height
            weight
          }
          vitalsOptOut
          location {
            integrationType
          }
        }
      }
      id
      isDeceased
      isHispanic
      lastName
      maritalStatus
      mbi
      middleName
      orgID
      patientID
      race
      sex
    }
  }`;

export const updatePatientForAppointment = /* GraphQL */ `
  mutation UpdatePatient(
    $condition: ModelPatientConditionInput
    $input: UpdatePatientInput!
  ) {
    updatePatient(condition: $condition, input: $input) {
      id
    }
  }`;

export const updateProviderForAppointment = /* GraphQL */ `
  mutation UpdateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      id
    }
  }`;

export const updateHra = /* GraphQL */ `
  mutation UpdateHra(
    $condition: ModelHraConditionInput
    $input: UpdateHraInput!
  ) {
    updateHra(condition: $condition, input: $input) {
      billingCode
      dateTime
      id
      isBilled
      isComplete
      locationID
      orgID
      patient {
        dob
        firstName
        lastName
        id
        patientID
        orgID
      }
      patientID
      phqTotal
      provider {
        id
        firstName
        lastName
        npi
      }
      providerID
      s3KeyPatientReport
      s3KeyProviderReport
      userID
      version
      vitals {
        bmi
        bpDiastolic
        bpSystolic
        height
        weight
      }
      vitalsOptOut
      location {
        integrationType
      }
    }
  }`;

export const updateHraForAwv = /* GraphQL */ `
  mutation UpdateHra(
    $condition: ModelHraConditionInput
    $input: UpdateHraInput!
  ) {
    updateHra(condition: $condition, input: $input) {
      id
      s3KeyPatientReport
      s3KeyProviderReport
      patient {
        firstName
        lastName
      }
      isComplete
      vitals {
        height
        weight
        bpDiastolic
        bpSystolic
      }
      vitalsOptOut
    }
  }`;

export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey($input: UpdateSurveyInput!) {
    updateSurvey(input: $input) {
      createdAt
      description
      disabled
      id
      name
      orgID
      org {
        customSurvey {
          id
        }
        id
        name
        locations {
          items {
            id
            name
            customSurvey {
              id
            }
          }
        }
        providers {
          items {
            firstName
            id
            isActive
            lastName
            providerID
            customSurvey {
              id
            }
          }
        }
      }
      updatedAt
    }
  }
  `;

export const updateSurveyQuestions = /* GraphQL */ `
  mutation UpdateSurveyQuestions($input: UpdateSurveyQuestionsInput!) {
    updateSurveyQuestions(input: $input) {
      currentSurveyVersion {
        id
        surveyID
        orgID
        createdAt
        langs
      }
      description
      id
      name
      orgID
      org {
        customSurvey {
          id
        }
        id
        name
        locations {
          items {
            id
            name
            customSurvey {
              id
            }
          }
        }
        providers {
          items {
            firstName
            id
            isActive
            lastName
            providerID
            customSurvey {
              id
            }
          }
        }
      }
      surveyVersions {
        nextToken
      }
    }
  }`;

export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      createdAt
        description
        id
        name
        orgID
        org
        {
          customSurvey {
            id
          }
          id
          name
          locations {
            items {
              id
              name
              customSurvey {
                id
              }
            }
          }
          providers {
            items {
              firstName
              id
              isActive
              lastName
              providerID
              customSurvey {
                id
              }
            }
          }
        }
        updatedAt
    }
  }`;

export const createAppointment = /* GraphQL */ `
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
      dateTime
      billingCode
      patient {
        firstName
        lastName
        dob
        mbi
        id
        sex
        patientID
        awvEligibleDate
        orgID
      }
      attendingProvider {
        firstName
        lastName
        providerID
        id
      }
      awvEligible
      hra {
        id
        s3KeyPatientReport
        s3KeyProviderReport
        patient {
          firstName
          lastName
        }
        isComplete
        vitals {
          height
          weight
          bpDiastolic
          bpSystolic
        }
        vitalsOptOut
      }
      locationID
    }
  }
`;

export const createHra = /* GraphQL */ `
  mutation CreateHra($input: CreateHraInput!) {
    createHra(input: $input) {
      id
      s3KeyPatientReport
      s3KeyProviderReport
    }
  }
`;

export const updateAppointment = /* GraphQL */ `
  mutation UpdateAppointment($input: UpdateAppointmentInput!) {
    updateAppointment(input: $input) {
      id
      dateTime
      billingCode
      locationID
      patient {
        firstName
        lastName
        dob
        mbi
        id
        sex
        patientID
        awvEligibleDate
        orgID
      }
      attendingProvider {
        firstName
        lastName
        providerID
        id
      }
      awvEligible
      hra {
        id
        s3KeyPatientReport
        s3KeyProviderReport
        patient {
          firstName
          lastName
        }
        isComplete
        vitals {
          height
          weight
          bpDiastolic
          bpSystolic
        }
        vitalsOptOut
      }
    }
  }
`;

export const setUserPin = /* GraphQL */ `
  mutation SetUserPin($pin: Int!) {
    setUserPin(pin: $pin)
  }
`;

export const resetTemporaryPassword = /* GraphQL */ `
  mutation ResetTemporaryPassword($email: AWSEmail!) {
    resetTemporaryPassword(email: $email)
  }
`;

export const pushHraReport = /* GraphQL */ `
  mutation PushHraReport($hraId: ID!) {
    pushHraReport(hraId: $hraId)
  }
`;

export const emailHraReport = /* GraphQL */ `
  mutation emailHraReport(
    $email: AWSEmail!
    $reportS3Path: String!
  ) {
    emailHraReport(email: $email, reportS3Path: $reportS3Path)
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      isActive
      address {
        streetAddress
        city
        state
        zip
        county
        country
      }
      telehealthURL
      hideRevWidget
      cognitoAppClientID
      cognitoIdpType
      ssoDomains
      totalPatientCount
      pinLength
      annualExpectedCompletions
      createdAt
      updatedAt
      locations {
        nextToken
      }
      providers {
        nextToken
      }
      users {
        nextToken
      }
      patients {
        nextToken
      }
      hras {
        nextToken
      }
      surveys {
        nextToken
      }
      customSurvey {
        id
        orgID
        name
        description
        disabled
        createdAt
        updatedAt
      }
    }
  }
`;
export const createLocationProvider = /* GraphQL */ `
  mutation CreateLocationProvider(
    $input: CreateLocationProviderInput!
    $condition: ModelLocationProviderConditionInput
  ) {
    createLocationProvider(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteLocationProvider = /* GraphQL */ `
  mutation DeleteLocationProvider(
    $input: DeleteLocationProviderInput!
    $condition: ModelLocationProviderConditionInput
  ) {
    deleteLocationProvider(input: $input, condition: $condition) {
      id
    }
  }
`;
