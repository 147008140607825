import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CLEAR_HRA_EDIT_FORM_ERROR,
  CLEAR_PATIENT_EDIT_FORM_ERROR,
  GET_PATIENTS_FAILURE,
  GET_PATIENTS_REQUEST,
  GET_PATIENTS_SUCCESS,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_HRA_FAILURE,
  UPDATE_PATIENTS_DATA,
  UPDATE_PATIENTS_SCOPEFILTER,
} from '../types/patientsTypes';

const schema = {
  type: 'object',
  properties: {
    data: { type: ['object', 'null'], optional: true },
    editError: { type: ['string', 'null'], optional: true },
    error: { type: ['string', 'null'], optional: true },
    hraEditError: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: { type: ['object'], optional: true },
  },
};

const initialState = {
  data: null,
  editError: null,
  error: null,
  hraEditError: null,
  loading: false,
  scopeFilter: {},
};

const patientsReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CLEAR_HRA_EDIT_FORM_ERROR: {
      return { ...state, hraEditError: null };
    }
    case CLEAR_PATIENT_EDIT_FORM_ERROR: {
      return { ...state, editError: null };
    }

    case GET_PATIENTS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_PATIENTS_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_PATIENTS_SUCCESS: {
      return { ...state, loading: false, data: action.payload, error: null };
    }

    case SET_INITIAL_STATE:
      return initialState;

    case UPDATE_PATIENT_FAILURE: {
      return { ...state, loading: false, editError: action.error };
    }

    case UPDATE_PATIENT_HRA_FAILURE: {
      return { ...state, loading: false, hraEditError: action.error };
    }

    case UPDATE_PATIENTS_DATA: {
      const newPatients = state.data;
      newPatients[action.payload.id] = { ...newPatients[action.payload.id], ...action.payload };

      return {
        ...state,
        data: {
          ...state.data,
          ...newPatients,
        },
      };
    }

    case UPDATE_PATIENTS_SCOPEFILTER: {
      return {
        ...state,
        scopeFilter: { organizations: action.organizations },
      };
    }

    default:
      return state;
  }
};

export default patientsReducer;
