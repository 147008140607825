export const CLEAR_ORGANIZATION_EDIT_FORM_ERROR = 'CLEAR_ORGANIZATION_EDIT_FORM_ERROR';
export const CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE';
export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const FETCH_ORGANIZATIONS_FAILURE = 'FETCH_ORGANIZATIONS_FAILURE';
export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';
export const FETCH_ORGANIZATIONS_SUCCESS = 'FETCH_ORGANIZATIONS_SUCCESS';
export const RESET_ORGANIZATION_CREATE_FORM = 'RESET_ORGANIZATION_CREATE_FORM';
export const RESET_ORGANIZATION_EDIT_FORM = 'RESET_ORGANIZATION_EDIT_FORM';
export const UPDATE_ORGANIZATION_CREATE_FORM = 'UPDATE_ORGANIZATION_CREATE_FORM';
export const UPDATE_ORGANIZATION_EDIT_FORM = 'UPDATE_ORGANIZATION_EDIT_FORM';
export const UPDATE_ORGANIZATION_FAILURE = 'UPDATE_ORGANIZATION_FAILURE';
export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
