import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Auth, Hub, Logger } from 'aws-amplify';
import AWS from 'aws-sdk/global';
import { has } from 'lodash';
import queryString from 'query-string';

import { amplifyAuthConfig, configureAmplify } from '../utils/cognito';
import { isUserFederated } from '../utils/helpers';
import { fetchFederatedDomains, popUserState } from './actions/authActions';

const logger = new Logger('AmplifyBridge');


export default class AmplifyBridge {
  constructor(store) {
    this.store = store;

    const queryParams = queryString.parse(window.location.search);
    if (has(queryParams, 'code')) {
      this.catchFederatedLogin();
    } else {
      // Fetch federated domains map and check the user on any instantiation that doesn't occur
      // during an oAuth redirect
      this.initializeBridge();
    }

    Hub.listen('auth', (data) => {
      if (['signIn', 'signOut', 'signIn_failure'].includes(data.payload.event)) {
        this.checkUser();
      }
    });
  }

  async checkUser() {
    await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        const { auth: { federatedDomains } } = this.store.getState();
        configureAmplify(true);
        this.configureAwsSdkCredentials();
        this.store.dispatch(popUserState(user, isUserFederated(user.attributes.email, federatedDomains)));
      })
      .catch(async (error) => {
        logger.error(error);
        if (!error.includes('not authenticated')) {
          this.store.dispatch(popUserState(null, false));
        }
      });
  }

  async catchFederatedLogin() {
    const cognitoAuthJsClient = new CognitoAuth({
      ClientId: amplifyAuthConfig.userPoolWebClientId,
      UserPoolId: amplifyAuthConfig.userPoolId,
      AppWebDomain: process.env.REACT_APP_AUTH_DOMAIN,
      TokenScopesArray: amplifyAuthConfig.oauthTokenScopes,
      RedirectUriSignIn: process.env.REACT_APP_AUTH_REDIRECT,
      RedirectUriSignOut: process.env.REACT_APP_AUTH_REDIRECT,
      ResponseType: amplifyAuthConfig.oauthResponseType,
    });
    cognitoAuthJsClient.userhandler = {
      async onSuccess(result) {
        logger.debug('parseCognitoWebResponse result:', result);
        window.location.href = 'dashboard';
      },
      async onFailure(err) {
        logger.error('parseCognitoWebResponse error:', err);
      },
    };

    // Leverages the Cognito Auth JS library to do the URL parsing and token fetching rather than
    // performing this on our own.
    await cognitoAuthJsClient.parseCognitoWebResponse(window.location.href);
  }

  async configureAwsSdkCredentials() {
    // Configure AWS SDK w/ unauthenticated Cognito credentials
    const {
      accessKeyId, secretAccessKey, identityId, sessionToken,
    } = await Auth.essentialCredentials(await Auth.currentCredentials());
    AWS.config.credentials = {
      accessKeyId,
      identityId,
      region: process.env.REACT_APP_AWS_REGION,
      secretAccessKey,
      sessionToken,
    };
  }

  async initializeBridge() {
    await this.store.dispatch(fetchFederatedDomains());
    this.checkUser();
  }
}
