import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  CREATE_CUSTOMSURVEY_FAILURE,
  CREATE_CUSTOMSURVEY_REQUEST,
  CREATE_CUSTOMSURVEY_SUCCESS,
  CUSTOMSURVEYS_SCOPEFILTER_UPDATE,
  FETCH_CUSTOMSURVEYBYID_REQUEST,
  FETCH_CUSTOMSURVEYBYID_SUCCESS,
  FETCH_CUSTOMSURVEYBYID_FAILURE,
  FETCH_CUSTOMSURVEYS_REQUEST,
  FETCH_CUSTOMSURVEYS_SUCCESS,
  FETCH_CUSTOMSURVEYS_FAILURE,
  PROPOGATE_BUILDER_TO_STORE,
  REFETCH_CUSTOMSURVEY_FAILURE,
  REFETCH_CUSTOMSURVEY_REQUEST,
  REFETCH_CUSTOMSURVEY_SUCCESS,
  UPDATE_CUSTOMSURVEY_FAILURE,
  UPDATE_CUSTOMSURVEY_REQUEST,
  UPDATE_CUSTOMSURVEY_SUCCESS,
  UPDATE_CUSTOMSURVEY_QUESTIONS_FAILURE,
  UPDATE_CUSTOMSURVEY_QUESTIONS_SUCCESS,
} from '../types/customSurveysTypes';

const schema = {
  type: 'object',
  properties: {
    builder: {
      type: 'object',
      properties: {
        error: { type: ['string', 'null', 'object'], optional: true },
        items: { type: 'array', optional: true },
        loading: { type: 'boolean' },
        survey: { type: ['object'] },
      },
    },
    createError: { type: ['string', 'null'], optional: true },
    data: { type: ['object', 'null'] },
    editError: { type: ['string', 'null'], optional: true },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
    scopeFilter: { type: ['object'], optional: true },
    sidebarLoading: { type: 'boolean' },
  },
};

const initialState = {
  builder: {
    error: null,
    items: [],
    loading: false,
    survey: {
      currentSurveyVersion: {
        questions: {},
      },
    },
  },
  createError: null,
  data: null,
  editError: null,
  error: null,
  loading: false,
  scopeFilter: {},
  sidebarLoading: false,
};

const customSurveysReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case CREATE_CUSTOMSURVEY_FAILURE: {
      return { ...state, loading: false, createError: action.error };
    }
    case CREATE_CUSTOMSURVEY_REQUEST: {
      return { ...state, createError: null };
    }
    case CREATE_CUSTOMSURVEY_SUCCESS: {
      const newSurveys = state.data;
      newSurveys[action.payload.id] = action.payload;

      return { ...state, data: { ...state.data, ...newSurveys } };
    }

    case CUSTOMSURVEYS_SCOPEFILTER_UPDATE: {
      return { ...state, scopeFilter: { organizations: action.organizations } };
    }

    case FETCH_CUSTOMSURVEYS_REQUEST: {
      return { ...state, loading: true };
    }
    case FETCH_CUSTOMSURVEYS_SUCCESS: {
      return { ...state, loading: false, data: action.payload };
    }
    case FETCH_CUSTOMSURVEYS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }

    case FETCH_CUSTOMSURVEYBYID_REQUEST: {
      return {
        ...state,
        builder: {
          ...state.builder,
          error: null,
          loading: true,
        },
      };
    }
    case FETCH_CUSTOMSURVEYBYID_SUCCESS: {
      return { 
        ...state,
        builder: {
          ...state.builder,
          loading: false,
          survey: action.payload,
        },
      };
    }
    case FETCH_CUSTOMSURVEYBYID_FAILURE: {
      return { 
        ...state,
        builder: {
          ...state.builder,
          loading: false,
          error: action.error,
        },
      };
    }

    case PROPOGATE_BUILDER_TO_STORE: {
      return { 
        ...state,
        builder: {
          ...state.builder,
          items: action.payload,
        },
      };
    }

    case REFETCH_CUSTOMSURVEY_FAILURE: {
      return { ...state, editError: action.error, sidebarLoading: true };
    }
    case REFETCH_CUSTOMSURVEY_REQUEST: {
      return { ...state, sidebarLoading: true };
    }
    case REFETCH_CUSTOMSURVEY_SUCCESS: {
      const newSurveys = state.data;
      newSurveys[action.payload.id] = action.payload;
      
      return {
        ...state,
        data: { ...state.data, ...newSurveys },
        sidebarLoading: false,
      };
    }

    case SET_INITIAL_STATE:
      return initialState;
    
    case UPDATE_CUSTOMSURVEY_FAILURE: {
      return { ...state, loading: false, editError: action.error };
    }
    case UPDATE_CUSTOMSURVEY_REQUEST: {
      return { ...state, editError: null };
    }
    case UPDATE_CUSTOMSURVEY_SUCCESS: {
      const newSurvey = state.data;
      newSurvey[action.payload.id] = { ...newSurvey[action.payload.id], ...action.payload };

      return {
        ...state,
        builder: {
          ...state.builder,
          survey: action.payload,
          loading: false,
        },
        data: {
          ...state.data,
          ...newSurvey,
        },
      };
    }

    case UPDATE_CUSTOMSURVEY_QUESTIONS_FAILURE: {
      return { 
        ...state,
        builder: {
          ...state.builder,
          error: action.error,
        },
      };
    }
    case UPDATE_CUSTOMSURVEY_QUESTIONS_SUCCESS: {
      return { 
        ...state,
        builder: {
          ...state.builder,
          survey: {
            ...state.builder.survey,
            currentSurveyVersion: {
              ...state.builder.survey.currentSurveyVersion,
              questions: action.payload,
            },
          },
        },
      };
    }

    default:
      return state;
  }
};

export default customSurveysReducer;
