import * as Sentry from '@sentry/react';
import {
  API,
  Auth,
  graphqlOperation,
} from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api';
import {
  findIndex,
  forEach,
  remove,
} from 'lodash';
import { isMobile } from 'react-device-detect';
import * as ReactGA from 'react-ga';

import { setUserPin as setUserPinMutation } from '../../graphql/mutations';
import {
  getOrganization,
  getUser,
  listFederatedDomains,
} from '../../graphql/queries';
import { pageGqlOp } from '../sharedMethods';
import {
  clearLocalStorageAuth,
  findLocalStorageItems,
  isAuthenticated,
} from '../../utils/helpers';
import ROUTE from '../router/routes';
import ServiceError from '../ServiceError';
import {
  FETCH_FEDERATED_DOMAINS_FAILURE,
  FETCH_FEDERATED_DOMAINS_REQUEST,
  FETCH_FEDERATED_DOMAINS_SUCCESS,
  FORGOT_PASSWORD_CHANGE_FAILURE,
  FORGOT_PASSWORD_CHANGE_SUCCESS,
  NEW_USER_CREDS_FAILURE,
  POP_USER_STATE,
  SET_FORGOT_USER_FAILURE,
  SET_FORGOT_USER_REQUEST,
  SET_FORGOT_USER_SUCCESS,
  SET_NEW_USER,
  SET_PIN_FAILURE,
  SET_PIN_REQUEST,
  SET_PIN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_OUT_SUCCESS,
} from '../types/authTypes';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import abilities from '../../utils/abilities';
import ability from '../Ability/Ability';
import { configureAmplify } from '../../utils/cognito';
import { lockForPin } from './pinActions';


export const fetchFederatedDomains = () => async (dispatch) => {
  dispatch({ type: FETCH_FEDERATED_DOMAINS_REQUEST });

  try {
    const graphResponse = await API.graphql({
      query: listFederatedDomains,
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    });
    dispatch({
      type: FETCH_FEDERATED_DOMAINS_SUCCESS,
      payload: JSON.parse(graphResponse.data.listFederatedDomains),
    });
  } catch (error) {
    dispatch({
      type: FETCH_FEDERATED_DOMAINS_FAILURE,
      payload: new ServiceError(error, 'Query: listFederatedDomains').toString(),
    });
  }
};


export const forgotPasswordCode = ({ username }) => async (dispatch) => {
  if (isAuthenticated()) {
    return dispatch(ROUTE.DASHBOARD.route());
  }

  dispatch({ type: SET_FORGOT_USER_REQUEST, payload: username });

  return Auth.forgotPassword(username)
    .then(() => dispatch({ type: SET_FORGOT_USER_SUCCESS, payload: username }))
    .catch(error => dispatch({ type: SET_FORGOT_USER_FAILURE, payload: error.message }));
};


export const forgotPasswordChange = ({ code, password }) => async (dispatch, getState) =>
  Auth.forgotPasswordSubmit(getState().auth.forgotUser, code, password)
    .then(() => dispatch({ type: FORGOT_PASSWORD_CHANGE_SUCCESS }))
    .catch(error => dispatch({ type: FORGOT_PASSWORD_CHANGE_FAILURE, payload: error.message }));

export const setNewUserCreds = ({ password }) => async (dispatch, getState) => {
  const { auth: { newUser } } = getState();

  if (!newUser) {
    dispatch({ type: NEW_USER_CREDS_FAILURE });
    dispatch(ROUTE.LOGIN.route());
  }

  return Auth.completeNewPassword(newUser, password)
    .then(() => dispatch(ROUTE.DASHBOARD.route()))
    .catch(error => dispatch({
      type: NEW_USER_CREDS_FAILURE,
      payload: error.log || error.message || error,
    }));
};


export const signInUser = ({ email, password }) => async (dispatch) => {
  if (!email || !password) {
    dispatch({ type: SIGN_IN_FAILURE, payload: 'Please enter both an email address and password.' });
    return;
  }

  dispatch({ type: SIGN_IN_REQUEST });

  return Auth.signIn({ username: email, password })
    .then((user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch({ type: SET_NEW_USER, payload: user });
        return dispatch(ROUTE.NEW_USER.route());
      }

      dispatch({ type: SIGN_IN_SUCCESS });
      if (isMobile) {
        return dispatch(ROUTE.AWV_SCHEDULE.route());
      }

      return dispatch(ROUTE.DASHBOARD.route());
    })
    .catch(error => dispatch({ type: SIGN_IN_FAILURE, payload: error.message }));
};


export const signOutUser = () => async (dispatch) => {
  // None of this needs to be explicitly done if the redirect in the next stanza succeeds
  // but it's being left in anyway for unforeseen edge cases
  try {
    await Auth.signOut();
    dispatch({ type: SIGN_OUT_SUCCESS });
  } catch (error) {
    dispatch({ type: SIGN_OUT_FAILURE, payload: error.message });
  }
  configureAmplify(false); // reconfigure to remove `graphql_headers`
  ability.update([]); // clear abilities
  await dispatch({ type: SET_INITIAL_STATE });

  // Redirect the user to the Cognito Logout endpoint which should follow IdP signout flow if enabled in
  // Cognito User Pools Identity Providers
  clearLocalStorageAuth();
  window.location.href = `https://${process.env.REACT_APP_AUTH_DOMAIN}/logout`
    + `?logout_uri=${process.env.REACT_APP_AUTH_REDIRECT}`
    + `&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT}`
    + '&response_type=code'
    + `&client_id=${(localStorage.getItem('activeAppClientId') || process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID)}`;
};


export const setUserPin = pin => async (dispatch) => {
  dispatch({ type: SET_PIN_REQUEST });

  try {
    const setUserPinResponse = await API.graphql(graphqlOperation(
      setUserPinMutation,
      { pin: parseInt(pin, 10) },
    ));
    if (setUserPinResponse.data.setUserPin) {
      await dispatch({
        type: SET_PIN_SUCCESS,
        payload: setUserPinResponse.data.setUserPin,
      });
      return dispatch(ROUTE.DASHBOARD.route());
    }
    return dispatch({
      type: SET_PIN_SUCCESS,
      payload: setUserPinResponse.data.setUserPin,
    });
  } catch (error) {
    return dispatch({
      type: SET_PIN_FAILURE,
      error: new ServiceError(error).toString(),
    });
  }
};


export const popUserState = (userPayload, isFederated = false) => async (dispatch, getState) => {
  if (!isAuthenticated()) {
    dispatch({ type: POP_USER_STATE, payload: null });
    return;
  }

  try {
    const user = await pageGqlOp(
      getUser,
      { id: userPayload.username, locationsLimit: 300 },
      'getUser.locations.items',
      'getUser.locations.nextToken',
      'locationsNextToken',
      'getUser',
    );
    if (!user) {
      dispatch(signOutUser());
      dispatch({ type: SIGN_IN_FAILURE, payload: 'The User could not be found on the service.' });
      return;
    }

    const { awvRole, emailAddress, locations, firstName, id, lastName, orgID, pin } = user;

    // Set user details for Sentry.io
    Sentry.setUser({
      id,
      orgID,
      awvRole,
    });

    let isRoot = false;
    if (userPayload && Object.prototype.hasOwnProperty.call(userPayload.signInUserSession.idToken.payload, 'cognito:groups')) {
      isRoot = userPayload.signInUserSession.idToken.payload['cognito:groups'].includes('Admin');
    }

    const token = isFederated ?
      userPayload.signInUserSession.idToken.jwtToken :
      findLocalStorageItems('CognitoIdentityServiceProvider\\..*\\.idToken', 'key').val;
    if (!token) {
      return dispatch(signOutUser());
    }

    let org = {};
    const userObject = {
      awvRole: awvRole || null,
      emailAddress: emailAddress || null,
      isFederated,
      isRoot,
      locations: locations.items || null,
      name: firstName ? `${firstName} ${lastName}` : '',
      org,
      payload: userPayload ? userPayload.signInUserSession.idToken.payload : null,
      token,
      id,
      pin,
    };

    if (userObject.awvRole) {
      const orgResponse = await API.graphql(graphqlOperation(getOrganization, { id: orgID }));
      org = orgResponse.data.getOrganization;
      userObject.org = org;
    }

    let priveleges = [];
    if (isRoot) {
      priveleges = abilities.ROOT;
    } else {
      priveleges = abilities[awvRole] || priveleges;

      // show custom survey builder menu if user is an org admin
      if (awvRole === 'ORG_ADMIN') {
        priveleges = [...abilities[awvRole], abilities.customSurvey];
      }

      // In cases where the user has a federated IdP type of `SAML`, this removes all CRUD priveleges except `read`
      // from the `user` and `user.location` entries in abilities
      const domain = emailAddress.split('@')[1];
      const federatedMapping = getState().auth.federatedDomains[domain];
      if (isFederated && federatedMapping?.providerType === 'SAML') {
        const userIdx = findIndex(priveleges, { subject: 'user' });
        remove(priveleges[userIdx].actions, val => val !== 'read');
      }
    }

    // show Clinic App menu for all users
    priveleges.push(abilities.awv);

    // iterate through `priveleges` array and create a new array of `priveleges` conforming to syntax for
    // `@casl/ability` > v5.0.0
    const newPriveleges = [];
    forEach(priveleges, (privelege) => {
      const { subject, actions } = privelege;
      forEach(actions, (action) => {
        newPriveleges.push({ subject, action });
      });
    });
    ability.update(newPriveleges);

    if (process.env.REACT_APP_FORCE_ANALYTICS === 'true' || process.env.NODE_ENV === 'production') {
      ReactGA.set({ dimension1: emailAddress, dimension2: org.id });
    }

    dispatch({ type: POP_USER_STATE, payload: userObject });
    if (isMobile) {
      dispatch(lockForPin());
    }
  } catch (error) {
    dispatch(signOutUser());
    dispatch({ type: SIGN_IN_FAILURE, payload: error.message });
  }
};
