import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  GET_PROFILE_FAILURE,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_SUCCESS,
} from '../types/profileTypes';

const schema = {
  type: 'object',
  properties: {
    data: { type: 'object' },
    error: { type: ['string', 'null'], optional: true },
    loading: { type: 'boolean' },
  },
};

const initialState = {
  data: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    pin: '',
    confirmPin: '',
  },
  error: null,
  loading: false,
};

const profileReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case GET_PROFILE_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case GET_PROFILE_REQUEST: {
      return { ...state, loading: true };
    }
    case GET_PROFILE_SUCCESS: {
      return { ...state, loading: false, data: { ...state.data, ...action.payload } };
    }

    case UPDATE_PROFILE_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }
    case UPDATE_PROFILE_SUCCESS: {
      return { ...state, loading: false, data: { ...state.data, ...action.payload } };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default profileReducer;
