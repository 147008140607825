import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import auth from './authReducer';
import awv from './awvReducer';
import billing from './billingReducer';
import customSurveys from './customSurveysReducer';
import dashboard from './dashboardReducer';
import generic from './genericReducer';
import hra from './hraReducer';
import locations from './locationsReducer';
import organizations from './organizationsReducer';
import patients from './patientsReducer';
import pin from './pinReducer';
import profile from './profileReducer';
import providers from './providersReducer';
import reporting from './reportingReducer';
import slideout from './slideoutReducer';
import users from './usersReducer';

const createRootReducer = history => combineReducers({
  auth,
  awv,
  billing,
  customSurveys,
  dashboard,
  generic,
  hra,
  locations,
  organizations,
  patients,
  pin,
  profile,
  providers,
  reporting,
  slideout,
  users,
  router: connectRouter(history),
});

export default createRootReducer;
