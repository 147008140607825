import { cloneDeep } from 'lodash';
import { validateReducerData } from '../../utils/helpers';
import {
  GET_REPORT_URL_FAILURE,
  GET_REPORT_URL_REQUEST,
  GET_REPORT_URL_SUCCESS,
  SET_INITIAL_STATE,
} from '../types/genericTypes';

const schema = {
  type: 'object',
  properties: {
    pdfError: { type: ['boolean', 'string'], optional: true },
    pdfIsLoading: { type: ['boolean', 'string'] },
  },
};

const initialState = {
  pdfError: false,
  pdfIsLoading: false,
};

const genericReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case GET_REPORT_URL_FAILURE: {
      return { ...state, pdfError: action.kind, pdfIsLoading: false };
    }
    case GET_REPORT_URL_REQUEST: {
      return { ...state, pdfIsLoading: action.payload };
    }
    case GET_REPORT_URL_SUCCESS: {
      return { ...state, pdfError: false, pdfIsLoading: false };
    }

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default genericReducer;
