import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Spinner extends Component {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
  };

  render() {
    return (
      <React.Fragment>
        <div className={`animated fadeIn pt-3 text-center ${this.props.className}`}>
          <div className="sk-circle">
            <div className="sk-circle1 sk-child" />
            <div className="sk-circle2 sk-child" />
            <div className="sk-circle3 sk-child" />
            <div className="sk-circle4 sk-child" />
            <div className="sk-circle5 sk-child" />
            <div className="sk-circle6 sk-child" />
            <div className="sk-circle7 sk-child" />
            <div className="sk-circle8 sk-child" />
            <div className="sk-circle9 sk-child" />
            <div className="sk-circle10 sk-child" />
            <div className="sk-circle11 sk-child" />
            <div className="sk-circle12 sk-child" />
          </div>
        </div>
        {this.props.text && <p className="spinner-text">{this.props.text}</p>}
      </React.Fragment>
    );
  }
}

export default Spinner;
