import { cloneDeep } from 'lodash';

import { validateReducerData } from '../../utils/helpers';
import { SET_INITIAL_STATE } from '../types/genericTypes';
import {
  GET_APPOINTMENT_FOR_SURVEY_FAILURE,
  GET_APPOINTMENT_FOR_SURVEY_REQUEST,
  GET_APPOINTMENT_FOR_SURVEY_SUCCESS,
  GET_HRA_LANGUAGES_CANCEL,
  GET_HRA_LANGUAGES_FAILURE,
  GET_HRA_LANGUAGES_REQUEST,
  GET_HRA_LANGUAGES_SUCCESS,
  GET_SURVEY_QUESTIONS_FAILURE,
  GET_SURVEY_QUESTIONS_REQUEST,
  GET_SURVEY_QUESTIONS_SUCCESS,
  PUT_HRA_FAILURE,
  PUT_HRA_REQUEST,
  PUT_HRA_SUCCESS,
  TOGGLE_EXIT_MODAL,
} from '../types/hraTypes';

const schema = {
  type: 'object',
  properties: {
    landing: {
      type: 'object',
      properties: {
        error: { type: ['string', 'null'], optional: true },
        loading: { type: 'boolean' },
      },
    },
    survey: {
      type: 'object',
      properties: {
        appointment: { type: 'object' },
        error: { type: ['string', 'null'], optional: true },
        loading: { type: 'boolean' },
        mainSurveyVersionID: { type: 'string' },
        questions: { type: 'array' },
        submitError: { type: ['string', 'null'], optional: true },
        submitting: { type: 'boolean' },
      },
    },
    languages: { type: 'array' },
    error: { type: ['string', 'null'], optional: true },
  },
};

const initialState = {
  landing: {
    loading: false,
    error: null,
  },
  languages: [],
  error: null,
  exitModalOpen: false,
  survey: {
    appointment: {},
    error: null,
    loading: false,
    mainSurveyVersionID: '',
    questions: [],
    submitError: null,
    submitting: false,
  },
};

const appointmentReducer = (state = cloneDeep(initialState), action) => {
  validateReducerData(schema, state);

  switch (action.type) {
    case GET_HRA_LANGUAGES_FAILURE:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: false,
          error: action.error,
        },
      };
    case GET_HRA_LANGUAGES_CANCEL:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: false,
          error: null,
        },
      };
    case GET_HRA_LANGUAGES_REQUEST:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: true,
          error: null,
        },
        survey: {
          ...state.survey,
          submitError: null,
        },
      };
    case GET_HRA_LANGUAGES_SUCCESS:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: false,
          error: null,
        },
        languages: action.payload,
      };
  
    case GET_SURVEY_QUESTIONS_FAILURE:
      return {
        ...state,
        survey: {
          ...state.survey,
          loading: false,
          error: action.error,
        },
      };
    case GET_SURVEY_QUESTIONS_REQUEST:
      return {
        ...state,
        survey: {
          ...state.survey,
          loading: true,
          error: null,
        },
      };
    case GET_SURVEY_QUESTIONS_SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          loading: false,
          error: null,
          questions: action.questions,
          mainSurveyVersionID: action.mainSurveyVersionID,
        },
      };

    case GET_APPOINTMENT_FOR_SURVEY_FAILURE:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: false,
          error: action.error,
        },
      };
    case GET_APPOINTMENT_FOR_SURVEY_REQUEST:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: true,
          error: null,
        },
      };
    case GET_APPOINTMENT_FOR_SURVEY_SUCCESS:
      return {
        ...state,
        landing: {
          ...state.landing,
          loading: false,
          error: null,
        },
        survey: {
          ...state.survey,
          appointment: action.payload,
        },
      };
    
    case PUT_HRA_FAILURE:
      return {
        ...state,
        survey: {
          ...state.survey,
          submitting: false,
          submitError: action.error,
        },
      };
    case PUT_HRA_REQUEST:
      return {
        ...state,
        survey: {
          ...state.survey,
          submitting: true,
        },
      };
    case PUT_HRA_SUCCESS:
      return {
        ...state,
        survey: {
          ...state.survey,
          submitting: false,
        },
      };
    
    case TOGGLE_EXIT_MODAL:
      return {
        ...state,
        exitModalOpen: action.payload,
      };

    case SET_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default appointmentReducer;
